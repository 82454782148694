'use strict';

angular.module('voucherApp')
    .service('Loading', function ($uibModal, $rootScope, $timeout) {


        this.open = function (title, showbar) {
            var data = {
                title: title,
                showbar: showbar
            };
            $rootScope.$broadcast("loading:open", data);
        };

        this.show = function (title, showbar) {
            this.open(title, showbar);
        };


        this.openTask = function (title, showbar) {
            var data = {
                title: "Einen Moment bitte...",
                task: title,
                showbar: showbar
            };
            $rootScope.$broadcast("loading:open", data);
        };


        this.task = function (message) {
            var data = {
                task: message
            };
            $rootScope.$broadcast("loading:task", data);
        };


        this.tick = function (tick, total, task) {
            var data = {
                tick: tick,
                total: total,
                task: task
            };
            $rootScope.$broadcast("loading:tick", data);
        };

        this.close = function () {
            $rootScope.$broadcast("loading:close");
        };

    });
