(function () {
    'use strict';

    angular
        .module('voucherApp')
        .controller('SystemConfigurationController', SystemConfigurationController);

    SystemConfigurationController.$inject = ['$filter', 'SystemConfigurationService'];

    function SystemConfigurationController(filter, SystemConfigurationService) {
        var vm = this;

        vm.allConfiguration = null;
        vm.configuration = null;

        SystemConfigurationService.get().then(function (configuration) {
            vm.configuration = configuration;
        });
        SystemConfigurationService.getEnv().then(function (configuration) {
            vm.allConfiguration = configuration;
        });
    }
})();
