(function () {
    'use strict';

    angular
        .module('voucherApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('settings.members', {
                parent: 'settings',
                url: '/members?page&sort',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_AGENT', 'ROLE_SYSTEM']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/views/settings/members/members.html',
                        controller: 'MembersController',
                        controllerAs: 'vm'
                    }
                }, params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'name,asc',
                        squash: true
                    }
                },
                ncyBreadcrumb: {
                    label: 'Mitglieder'
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort)
                        };
                    }]
                }
            })
            .state('settings.members.new', {
                parent: 'settings.members',
                url: '/new',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_AGENT', 'ROLE_SYSTEM']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/views/settings/members/dialog/members.dialog.html',
                        controller: 'MembersDialogController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: 'Neues Mitglied'
                },
                resolve: {
                    member: function () {
                        return {};
                    }
                }
            })
            .state('settings.members.edit', {
                parent: 'settings.members',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_AGENT', 'ROLE_SYSTEM']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/views/settings/members/dialog/members.dialog.html',
                        controller: 'MembersDialogController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: 'Mitglied bearbeiten'
                },
                resolve: {
                    member: ['Members', '$stateParams', function (Members, $stateParams) {
                        return Members.get({id: $stateParams.id}).$promise;
                    }]
                }
            })
            .state('settings.members.show', {
                parent: 'settings.members',
                url: '/{id}',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_AGENT', 'ROLE_SYSTEM']
                },
                ncyBreadcrumb: {
                    label: 'Mitglied'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/views/settings/members/detail/members.detail.html',
                        controller: 'MembersDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    member: ['Members', '$stateParams', function (Members, $stateParams) {
                        return Members.get({id: $stateParams.id}).$promise;
                    }]
                }
            });
    }
})();
