(function () {
    'use strict';

    angular
        .module('voucherApp')
        .controller('ClearingVouchersDetailController', ClearingVouchersDetailController);


    ClearingVouchersDetailController.$inject = ['$scope', 'Dialogs', 'toastr', 'VoucherUtils', 'Vouchers', 'systemInfoLoader', 'docket', 'Loading', '$state', 'GraphQL', '$q', 'Sales', 'systemInfo'];

    function ClearingVouchersDetailController($scope, Dialogs, toastr, VoucherUtils, Vouchers, systemInfoLoader, docket, Loading, $state, GraphQL, $q, Sales, systemInfo) {
        var vm = this;
        vm.worths = systemInfoLoader.worths;


        vm.docket = docket;

        vm.predicate = "id";
        vm.reverse = true;

        vm.list = {
            page: 1,
            size: 20,
            total: 0,
            items: []
        };


        function loadStats() {

            var request = {
                name: "docket",
                variables: {id: vm.docket.id},
                values: [
                    "id",
                    "date",
                    "state",
                    {name: "member", values: ["id", "name"]},
                    {
                        name: "stats", values: [
                            "worth",
                            "minSerial",
                            "maxSerial",
                            "count",
                            "value"
                        ]
                    }
                ]
            };

            GraphQL.then(request, function (data) {
                vm.details = data.docket;
            })

        }

        vm.loadList = function () {

            var request = {
                name: "docket",
                variables: {
                    id: vm.docket.id
                },
                values: [
                    "id",
                    // "vouchersSize",
                    {
                        name: "vouchers",
                        variables: {
                            page: vm.list.page - 1,
                            size: vm.list.size,
                            sort: sort()
                        },
                        values: [
                            "id",
                            "state",
                            "number",
                            "serialString",
                            "serialNumber",
                            "worth"
                        ]
                    }
                ]
            };

            GraphQL.then(request, function (data, ctx, headers) {
                vm.list.items = data.docket.vouchers;
                vm.list.total = headers("X-Total-Count");
            })


        };


        function sort() {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }


        loadStats();
        vm.loadList();


        vm.removeVoucher = function (voucher) {

            Dialogs.ask("Diesen Eintrag von dieser Aktivierung bzw. dem Verkauf löschen?", "Keine tatsächlich verkauften "+systemInfo.plural()+" löschen, denn damit ist dieser dann wieder im Bestand und nicht mehr aktiviert").then(function () {
                Sales.removeVoucher({saleId: vm.sale.id, voucherId: voucher.id}, function () {
                    toastr.success("Eintrag wurde entfernt");
                    vm.loadList();
                }, function () {
                    toastr.error("Dies ist nicht mehr möglich!");
                })
            })
        }

        // vm.updateValues();


    }
})();
