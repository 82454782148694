'use strict';

angular.module('voucherApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('book-out', {
                parent: 'app',
                url: '/book-out?tab',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SYSTEM', 'ROLE_AGENT', 'ROLE_REVISOR']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/views/book-out/book-out.html',
                        controller: 'BookOutController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: 'Ausbuchen'
                },
                params: {
                    tab: {
                        value: "0"
                    }
                },
                reloadOnSearch: false
            })
    });
