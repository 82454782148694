(function () {
    'use strict';


    angular
        .module('voucherApp')
        .controller('DialogChooseModalController', DialogChooseModalController);

    DialogChooseModalController.$inject = ['$scope', '$uibModalInstance', 'list', 'title', "prop"];

    function DialogChooseModalController($scope, $uibModalInstance, list, title, prop) {

        $scope.list = list;
        $scope.prop = prop;
        $scope.title = title;


        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.choose = function (index) {
            $uibModalInstance.close($scope.list[index]);
        }


    }
})();
