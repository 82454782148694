'use strict';

angular.module('voucherApp')
    .controller('NavbarTopController', function ($scope, $location, $state, Auth, $rootScope, $window, Principal, $q, menuHandler, toastr) {

        $scope.clutter = false;
        $scope.authenticated = Principal.isAuthenticated();
        $scope.$state = $state;

        $scope.totalbox = 0;

        Principal.identity().then(function (identity) {
            $scope.user = identity;
        });


        $rootScope.$watch("pageTitle", function () {
            $scope.pageTitle = $rootScope.pageTitle;
        });



        $scope.formatActivity = function(activityName){
            if(!activityName){
                return "";
            }
            return activityName.replace(/\{\{[\s\S]*?\}\}/, '');
        }


        $rootScope.$on("$authLogout", function () {
            $scope.authenticated = Principal.isAuthenticated();
        });

        $rootScope.$on("$authLogin", function () {
            $scope.authenticated = Principal.isAuthenticated();
        });

        $scope.logout = function () {
            Auth.logout();
            $state.go("login");
        };

        $scope.navbarExpandToggle = function () {
            menuHandler.toggle();
        };


        $scope.reload = function () {
            location.reload(true);
        };

        $scope.activities = [];

    });
