'use strict';

angular.module('voucherApp')
    .directive('ibDateRangePicker', function ($timeout) {
        return {
            scope: {
                range: '='
            },
            templateUrl: 'app/directives/ibDateRangePicker/ibDateRangePicker.html',
            restrict: 'EA',
            replace:true,
            transclude: true,
            link: function (scope, element, attrs) {

                if (_.isNil(scope.range)) {
                    scope.range = {startDate: moment().subtract(29, 'days'), endDate: moment()};
                }


                function applyRange(from, to) {
                    scope.range = {
                        startDate: from.startOf('day'),
                        endDate: to.endOf('day')
                    }
                }


                scope.chooseRange = function (key) {

                    if (key === 'today') {
                        applyRange(moment(), moment());
                    }
                    if (key === 'yesterday') {
                        applyRange(moment().subtract(1, 'days'), moment().subtract(1, 'days'));
                    }
                    if (key === 'beforeYesterday') {
                        applyRange(moment().subtract(2, 'days'), moment().subtract(2, 'days'));
                    }
                    if (key === 'last7Days') {
                        applyRange(moment().subtract(6, 'days'), moment());
                    }
                    if (key === 'last14Days') {
                        applyRange(moment().subtract(14, 'days'), moment());
                    }
                    if (key === 'last30Days') {
                        applyRange(moment().subtract(29, 'days'),moment());
                    }
                    if (key === 'thisMonth') {
                        applyRange(moment().startOf('month'), moment().endOf('month'));
                    }
                    if (key === 'lastMonth') {
                        applyRange(moment().subtract(1, 'months').startOf('month'), moment().subtract(1, 'months').endOf('month'));
                    }
                    if (key === 'secondLastMonth') {
                        applyRange(moment().subtract(2, 'months').startOf('month'), moment().subtract(2, 'months').endOf('month'));
                    }
                    if (key === 'thisQuarter') {
                        applyRange(moment().startOf('quarter'), moment().startOf('quarter').add(2, 'months').endOf('month'));
                    }
                    if (key === 'lastQuarter') {
                        applyRange(moment().subtract(1, 'quarters').startOf('quarter'), moment().subtract(1, 'quarters').startOf('quarter').add(2, 'months').endOf('month'));
                    }
                    if (key === 'secondLastQuarter') {
                        applyRange(moment().subtract(2, 'quarters').startOf('quarter'), moment().subtract(2, 'quarters').startOf('quarter').add(2, 'months').endOf('month'));
                    }
                    if (key === 'thisYear') {
                        applyRange(moment().startOf('year'), moment().endOf('year'));
                    }
                    if (key === 'lastYear') {
                        applyRange(moment().subtract(1, 'years').startOf('year'), moment().subtract(1, 'years').endOf('year'));
                    }
                    if (key === 'secondLastYear') {
                        applyRange(moment().subtract(2, 'years').startOf('year'), moment().subtract(2, 'years').endOf('year'));
                    }
                };


                scope.rangePickerOptions = {
                    showDropdowns: true,
                    startDate: scope.range.startDate,
                    endDate: scope.range.endDate,
                    alwaysShowCalendars: true,
                    ranges: {
                        'Heute': [moment(), moment()],
                        'Gestern': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                        'Letzte 7 Tage': [moment().subtract(6, 'days'), moment()],
                        'Letzte 30 Tage': [moment().subtract(29, 'days'), moment()],
                        'Diesen Monat': [moment().startOf('month'), moment().endOf('month')],
                        'Letzten Monat': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                        'Dieses Quartal': [moment().startOf('quarter'), moment().startOf('quarter').add(2, 'months').endOf('month')],
                        'Letztes Quartal': [moment().subtract(1, 'quarters').startOf('quarter'), moment().subtract(1, 'quarters').startOf('quarter').add(2, 'months').endOf('month')]
                    },
                    locale: {
                        "format": "DD.MM.YYYY",
                        "separator": " - ",
                        "applyLabel": "Anzeigen",
                        "cancelLabel": "Abbrechen",
                        "fromLabel": "von",
                        "toLabel": "bis",
                        "customRangeLabel": "Benutzerdefiniert",
                        "weekLabel": "W",
                        "daysOfWeek": [
                            "So",
                            "Mo",
                            "Di",
                            "Mi",
                            "Do",
                            "Fr",
                            "Sa"
                        ],
                        "monthNames": [
                            "Januar",
                            "Februar",
                            "März",
                            "April",
                            "Mai",
                            "Juni",
                            "Juli",
                            "August",
                            "September",
                            "Oktober",
                            "November",
                            "Dezember"
                        ],
                        "firstDay": 1
                    }
                };

                $timeout(function(){
                    applyRange(scope.range.startDate, scope.range.endDate)
                },10);


            }
        };
    });
