'use strict';

angular.module('voucherApp')
    .directive('checkIcon', function ($timeout) {
        return {
            scope: {
                model: '=ngModel'
            },
            templateUrl: 'app/directives/checkIcon/checkIcon.html',
            restrict: 'EA',
            replace: true,
            link: function ($scope, element, $attrs, ngModel) {

                $scope.active = false;

                $scope.$watch("model", function (newVal) {
                    if (newVal && (newVal === 'true' || newVal === true)) {
                        $scope.active = true;
                    } else {
                        $scope.active = false;
                    }
                })
            }
        }
    });
