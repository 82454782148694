(function() {
    'use strict';

    angular
        .module('voucherApp')
        .controller('IssuersDetailController', IssuersDetailController);

    IssuersDetailController.$inject = ['$stateParams', 'issuer'];

    function IssuersDetailController($stateParams, issuer) {
        var vm = this;

        vm.issuer = issuer;
    }
})();
