'use strict';

angular.module('voucherApp')
    .factory('Sales', function ($resource) {
        return $resource('api/sales/:id', {}, {

            'getSales': {
                url: 'api/sales',
                method: 'GET',
                isArray: true
            },
            'createList': {
                method: 'POST',
                isArray: true,
                url: 'api/sales/list'
            },
            'createRange': {
                method: 'POST',
                isArray: false,
                url: 'api/sales/range'
            },
            'removeVoucher': {
                method: 'DELETE',
                isArray: false,
                url: 'api/sales/:saleId/voucher/:voucherId'
            }

        });
    });
