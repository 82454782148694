'use strict';

angular.module('voucherApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('audit', {
                parent: 'app',
                url: '/audit?tab',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SYSTEM', 'ROLE_AGENT', 'ROLE_REVISOR']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/views/audit/audit.html',
                        controller: 'AuditController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: 'Abrechnungen Prüfen'
                },
                params: {
                    tab: {
                        value: "0"
                    }
                },
                reloadOnSearch: false
            })
            .state('audit-detail', {
                parent: 'audit',
                url: '/{id}',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SYSTEM', 'ROLE_AGENT', 'ROLE_REVISOR']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/views/audit/dialog/audit.dialog.html',
                        controller: 'AuditDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    audit: ['$stateParams', 'Audits', function ($stateParams, Audits) {
                        return Audits.get({id: $stateParams.id}).$promise;
                    }]
                },
                ncyBreadcrumb: {
                    label: 'Abrechnungsdetails prüfen'
                }
            })
    });
