'use strict';

angular.module('voucherApp')
    .factory('Members', function ($resource) {
        return $resource('api/members/:id', {}, {

            'query': {method: 'GET', isArray: true},
            'get': {method: 'GET'},
            'save': {method: 'POST'},
            'update': {method: 'PUT'},
            'updateBanking': {method: 'PUT', url: 'api/members/banking', isArray: false},
            'delete': {method: 'DELETE'},
        });
    });
