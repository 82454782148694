'use strict';

angular.module('voucherApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('submit-vouchers', {
                parent: 'app',
                url: '/submit-vouchers?tab',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SYSTEM', 'ROLE_AGENT', 'ROLE_MEMBER']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/views/submit-vouchers/submit-vouchers.html',
                        controller: 'SubmitVouchersController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: 'Einreichen'
                },
                params: {
                    tab: {
                        value: "0"
                    }
                },
                reloadOnSearch: false
            })

            .state('submit-vouchers-member', {
                parent: 'submit-vouchers',
                url: '/{id}?dialog-tab',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SYSTEM', 'ROLE_AGENT', 'ROLE_MEMBER']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/views/submit-vouchers/dialog/submit-vouchers.dialog.html',
                        controller: 'SubmitVouchersDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    member: ['$stateParams', 'Members', function ($stateParams, Members) {
                        return Members.get({id: $stateParams.id}).$promise;
                    }]
                },
                ncyBreadcrumb: {
                    label: 'Einreichen'
                },
                params: {
                    'dialog-tab': {
                        value: "0"
                    }
                },
                reloadOnSearch: false
            })

            .state('submit-vouchers-detail', {
                parent: 'submit-vouchers',
                url: '/detail/{id:int}',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SYSTEM', 'ROLE_AGENT', 'ROLE_MEMBER']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/views/submit-vouchers/detail/submit-vouchers.detail.html',
                        controller: 'SubmitVouchersDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    sale: ['$stateParams', 'Sales', function ($stateParams, Sales) {
                        return Sales.get({id: $stateParams.id}).$promise;
                    }]
                },
                ncyBreadcrumb: {
                    label: 'Einreichung'
                }
            })
    });
