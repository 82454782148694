(function () {
    'use strict';

    angular
        .module('voucherApp')
        .controller('SubmitVouchersController', SubmitVouchersController);


    SubmitVouchersController.$inject = ['$scope', 'Dialogs', 'toastr', 'VoucherUtils', 'Vouchers', 'systemInfoLoader', 'GraphQL', 'Submissions', 'TabUtil'];

    function SubmitVouchersController($scope, Dialogs, toastr, VoucherUtils, Vouchers, systemInfoLoader, GraphQL, Submissions, TabUtil) {
        var vm = this;


        vm.stock = [];
        vm.lastSubmissions = [];
        vm.tabs = TabUtil.watch($scope, "tab");


        $scope.$watch("vm.tabs.active", function (newVal) {
            $scope.$emit('bb.tab-changed', {});
        });

        vm.predicate = "id";
        vm.reverse = false;

        vm.list = {
            page: 1,
            size: 20,
            total: 0,
            items: []
        };


        function loadStock() {
            var request = {
                name: "statistics",
                values: [{
                    name: "stockOutside", values: [
                        "worth",
                        "count",
                        "value",
                        "min",
                        "minSerial",
                        "max",
                        "maxSerial"]
                }]
            };

            GraphQL.query(request).then(function (response) {
                vm.stock = response.data.statistics.stockOutside;
            });
        }

        function loadMembers() {
            var request = {
                name: "members",
                values: ["id", "name"]
            };
            GraphQL.then(request, function (data) {
                vm.members = data.members;
            });
        }

        function loadLastSubmission() {
            var request = {
                name: "submissions",
                variables: {
                    page: 0,
                    size: 10,
                    sort: "id,desc"
                },
                values: ["id", "submissiondate", "redemptiondate", "state",
                    {name: "member", values: ["id", "name"]}
                ]
            };
            GraphQL.then(request, function (data, ctx, headers) {
                vm.lastSubmissions = data.submissions;
            });
        }


        vm.removeSubmittedSubmission = function (submission, index) {
            Dialogs.ask("Wirklich diese Einreichung löschen?").then(function () {
                Submissions.deleteSubmission({id: submission.id}, function () {
                    toastr.success("Einreichung gelöscht");
                    vm.loadList();
                }, function () {
                    toastr.error("Diese Einreichung kann nicht mehr gelöscht werden!");
                })
            })
        };


        vm.loadList = function () {
            var request = {
                name: "submissions",
                variables: {
                    page: vm.list.page - 1,
                    size: 20,
                    sort: sort()
                },
                values: [
                    "id",
                    "submissiondate",
                    "redemptiondate",
                    "state",
                    {
                        name: "member",
                        values: ["id", "name"]
                    },
                    {
                        name: "voucher",
                        values: ["id", "serialString", "worthLabel", "state"]
                    },
                    {
                        name: "docket",
                        values: ["id", "date", "state"]
                    }

                ]
            };
            GraphQL.then(request, function (data, ctx, headers) {
                vm.list.items = data.submissions;
                vm.list.total = headers('X-Total-Count');
            });
        };


        function sort() {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }


        loadMembers();
        loadStock();
        loadLastSubmission();
        vm.loadList();


    }
})();
