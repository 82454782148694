(function() {
    'use strict';

    angular
        .module('voucherApp')
        .controller('RequestResetController', RequestResetController);

    RequestResetController.$inject = ['$timeout', 'Auth', 'systemInfo'];

    function RequestResetController ($timeout, Auth, systemInfo) {
        var vm = this;

        vm.requestReset = requestReset;
        vm.resetAccount = {};
        vm.error = false;
        vm.success = false;

        $timeout(function () {
            $('#email').focus();
        });

        vm.label = systemInfo.type();

        function requestReset () {

            vm.error = false;

            Auth.resetPasswordInit(vm.resetAccount.email).then(function () {
                vm.success = true;
            }).catch(function (response) {
                vm.success = false;
                vm.error = true;
            });
        }
    }
})();
