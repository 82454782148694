(function () {
    'use strict';

    angular
        .module('voucherApp')
        .controller('AuditDialogController', AuditDialogController);


    AuditDialogController.$inject = ['$scope', 'Dialogs', 'toastr', 'VoucherUtils', 'Vouchers', 'systemInfoLoader', 'audit', 'GraphQL', '$state'];

    function AuditDialogController($scope, Dialogs, toastr, VoucherUtils, Vouchers, systemInfoLoader, audit, GraphQL, $state) {
        var vm = this;
        vm.worths = systemInfoLoader.worths;
        vm.audit = audit;

        vm.predicate = "id";
        vm.reverse = true;

        vm.list = {
            page: 1,
            size: 20,
            total: 0,
            items: []
        };

        vm.loadDetails = function () {
            var request = {
                name: "audit",
                variables: {id: vm.audit.id},
                values: ["id", "date", {name: "revisor", values: ["id", "name"]}]
            }

            GraphQL.then(request, function (result) {
                vm.details = result.audit;
            })
        };

        vm.loadList = function () {

            var request = {
                name: "audit",
                variables: {
                    id: vm.audit.id
                },
                values: [
                    "id",
                    // "vouchersSize",
                    {
                        name: "dockets",
                        variables: {
                            page: vm.list.page - 1,
                            size: vm.list.size,
                            sort: sort()
                        },
                        values: [
                            "id",
                            "state",
                            "date",
                            {
                                name: "member", values: ["id", "name"]
                            },
                            {
                                name: "statsTotal", values: ["count", "total"]
                            }
                            // "number",
                            // "serialString",
                            // "serialNumber",
                            // "worth"
                        ]
                    }
                ]
            };

            GraphQL.then(request, function (data, ctx, headers) {
                vm.list.items = data.audit.dockets;
                vm.list.total = headers("X-Total-Count");
            })


        };


        function sort() {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }


        vm.loadDetails();
        vm.loadList();


    }
})();
