(function() {
    'use strict';

    angular
        .module('voucherApp')
        .controller('UserManagementShowController', UserManagementShowController);

    UserManagementShowController.$inject = ['$stateParams', 'User'];

    function UserManagementShowController($stateParams, User) {
        var vm = this;

        vm.load = load;
        vm.user = {};

        vm.load($stateParams.id);

        function load(id) {
            User.get({id: id}, function(result) {
                vm.user = result;
            });
        }
    }
})();
