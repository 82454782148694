'use strict';

angular.module('voucherApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('voucher', {
                parent: 'app',
                url: '/voucher/{id:int}',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SYSTEM', 'ROLE_AGENT', 'ROLE_USER', 'ROLE_ISSUER', 'ROLE_REVISOR']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/views/voucher/voucher.html',
                        controller: 'VoucherController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: 'Details'
                },
                resolve: {
                    voucherId: ['$stateParams', function ($stateParams) {
                        return $stateParams.id;
                    }]
                },
            })
    });
