'use strict';

angular.module('voucherApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('stock-vouchers', {
                parent: 'app',
                url: '/stock-vouchers',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SYSTEM', 'ROLE_AGENT']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/views/stock-vouchers/stock-vouchers.html',
                        controller: 'StockVouchersController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: 'Lager'
                }
            })
            .state('stock-vouchers-worth', {
                parent: 'stock-vouchers',
                url: '/{worth:int}',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SYSTEM', 'ROLE_AGENT']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/views/stock-vouchers/dialog/stock-vouchers.dialog.html',
                        controller: 'StockVouchersDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    worthValue: ['$stateParams', function ($stateParams) {
                        return $stateParams.worth;
                    }]
                },
                ncyBreadcrumb: {
                    label: 'Einlagern'
                }
            })
    });
