(function () {
    'use strict';

    angular
        .module('voucherApp')
        .controller('BankingController', BankingController);

    BankingController.$inject = ['Settings', 'Account', 'toastr', '$state', 'Principal', 'Members'];

    function BankingController(Settings, Account, toastr, $state, Principal, Members) {
        var vm = this;

        vm.needsConfirm = false;
        vm.member = {};

        Principal.identity().then(function (ident) {
            vm.needsConfirm = ident.needsAccount;
            if (!_.isNil(ident.memberId)) {
                Members.get({id: ident.memberId}, function (result) {
                    vm.member = result;
                });
            } else {
                vm.needsConfirm = false;
            }
        })

        vm.save = function () {
            Members.updateBanking(vm.member, function (response) {
                toastr.success("Bankdaten wurden geändert!");
                Principal.identity(true).then(function () {
                    if(vm.needsConfirm) {
                        $state.go("home");
                    }
                    vm.needsConfirm = false;
                })

            }, function () {
                toastr.error("Fehler beim Ändern der Bankdaten");
            })
        }

    }
})();
