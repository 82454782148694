(function () {
    /*jshint bitwise: false*/
    'use strict';

    angular
        .module('voucherApp')
        .factory('GraphQL', GraphQL);

    GraphQL.$inject = ['$rootScope', '$q', '$http', 'GraphQLSocket'];

    function GraphQL($rootScope, $q, $http, GraphQLSocket) {

        var service = {
            query: query,
            then: then,
            bind: bind,
            subscribe: subscribe,
            subscribeBind: subscribeBind,
            mutation: mutation,
            bindMutation: bindMutation
        };

        return service;


        function then(thequery, callback, context) {
            var deferred = $q.defer();
            this.query(thequery, context).then(function (response) {

                if(_.isFunction(callback)){
                    callback(response.data, context, response.headers);
                }
                deferred.resolve(response);
            }, function (error) {
                deferred.reject(error);
            });
            return deferred.promise;
        }

        function query(query, context) {
            if (_.isArrayLike(query)) {
                query = {
                    name: "query",
                    values: query
                }
            }
            if (_.isObject(query)) {
                query = buildFromObject(query);
            }
            var deferred = $q.defer();

            var request = {
                query: query,
                variables: {}
            };
            $http.post("/graphql", request).then(function (result) {
                var data = result.data;
                if (data.errors) {
                    console.warn("GraphQL query was invalid!");
                    console.log(data.errors);
                    deferred.reject(data.errors);
                } else {
                    var resolved = {
                        data: data.data,
                        headers: result.headers
                    };
                    if (!_.isNil(context)) {
                        resolved.context = context;
                    }
                    deferred.resolve(resolved);
                }
            }, function (error) {
                deferred.reject(error);
            });
            return deferred.promise;
        }


        function bind(theQuery, vm, sub) {
            if (_.isObject(theQuery)) {
                theQuery = buildFromObject(theQuery);
            }
            var deferred = $q.defer();
            this.query(theQuery).then(function (result) {
                var data = result.data;
                var headers = result.headers;
                if (_.isNil(sub)) {
                    _.merge(vm, data);
                } else {
                    data = data[sub];
                    _.merge(vm, data);
                }
                var resolved = {
                    data: data,
                    headers: headers
                };
                deferred.resolve(resolved);
            }, function (error) {
                deferred.reject(error);
            });
            return deferred.promise;
        }


        function bindMutation(theQuery, vm, sub) {
            if (_.isObject(theQuery)) {
                theQuery = buildFromObject(theQuery);
            }
            var deferred = $q.defer();
            this.mutation(theQuery).then(function (result) {
                var data = result.data;
                var headers = result.headers;
                if (_.isNil(sub)) {
                    _.merge(vm, data);
                } else {
                    data = data[sub];
                    _.merge(vm, data);
                }
                var resolved = {
                    data: data,
                    headers: headers
                };
                deferred.resolve(resolved);
            }, function (error) {
                deferred.reject(error);
            });
            return deferred.promise;
        }


        function mutation(theQuery, vm, sub) {
            if (_.isObject(theQuery)) {
                theQuery = buildFromObject(theQuery);
            }
            var deferred = $q.defer();

            theQuery = "mutation " + theQuery;
            var request = {
                query: theQuery,
                variables: {}
            };
            $http.post("/graphql", request).then(function (result) {
                var data = result.data;
                if (data.errors) {
                    console.warn("GraphQL query was invalid!");
                    console.log(data.errors);
                    deferred.reject(data.errors);
                } else {
                    var resolved = {
                        data: data.data,
                        headers: result.headers
                    };
                    deferred.resolve(resolved);
                }
            }, function (error) {
                deferred.reject(error);
            });
            return deferred.promise;


        }

        function subscribe(theQuery, scope, callback, errorCallback) {
            if (_.isObject(theQuery)) {
                theQuery = "subscription " + buildFromObject(theQuery);
            }
            GraphQLSocket.subscribe(theQuery, function (result) {
                if (callback) {
                    if (result.data) {
                        callback(result.data);
                    }
                }
            }).then(function (id) {
                if (scope) {
                    scope.$on("$destroy", function () {
                        GraphQLSocket.unsubscribe(id);
                    })
                }
            }, function (error) {
                if (errorCallback) {
                    errorCallback(error);
                }
            })
        }


        function subscribeBind(theQuery, vm, scope) {
            GraphQLSocket.subscribe(theQuery, function (result) {
                _.merge(vm, result.data);
            }).then(function (id) {
                if (scope) {
                    scope.$on("$destroy", function () {
                        GraphQLSocket.unsubscribe(id);
                    })
                }
            })
        }


        function buildFromObject(obj) {
            var q = "";
            if (obj.name === 'query') {
                q = objectValue(obj);
            } else {
                q = "{";
                q = q + objectValue(obj);
                q = q + "}";
            }
            if (!_.isNil(obj.fragments)) {
                var qFragment = "";
                obj.fragments.forEach(function (f) {
                    var frag = "fragment " + f.name + " on " + f.on;
                    frag = frag + objectValueBuild(f, false);
                    qFragment = qFragment + "\n" + frag;
                });
                q = q + qFragment;
            }
            return q;
        }

        function objectValue(obj) {
            return objectValueBuild(obj, true);
        }

        function objectValueBuild(obj, withName) {
            var q = "";
            if (!_.isNil(obj.alias)) {
                q = obj.alias + ": ";
            }
            if (withName) {
                q = q + obj.name;
            }
            if (!_.isNil(obj.variables)) {
                var vars = _.toPairs(obj.variables);
                var strings = [];
                vars.forEach(function (e) {
                    var value = e[1];
                    // if (_.isString(value) && isNaN(value)) {
                    if (_.isString(value)) {
                        value = "\"" + value + "\"";
                    }
                    if (_.isObject(value)) {
                        value = JSON.stringify(value);
                        value = value.replace(/\"([^(\")"]+)\":/g, "$1:");
                    }
                    strings.push(e[0] + ": " + value);
                })
                q = q + "(" + _.join(strings) + ")";
            }
            if (_.isNil(obj.values) || obj.values.length === 0) {
                // console.error("object values for graph cannot be null");
            } else {
                q = q + "{";
                var sep = "";
                obj.values.forEach(function (value) {
                    if (_.isString(value)) {
                        q = q + sep + value;
                        sep = ", ";
                    } else {
                        if (_.isObject(value)) {
                            q = q + sep + objectValue(value);
                            sep = ", ";
                        }
                    }
                });
                q = q + "}";
            }

            return q;
        }

    }
})();
