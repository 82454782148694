(function () {
    'use strict';

    angular
        .module('voucherApp')
        .controller('AuditController', AuditController);


    AuditController.$inject = ['$scope', 'Dialogs', 'toastr', 'VoucherUtils', 'Audits', 'systemInfoLoader', 'AuthServerProvider', 'GraphQL', '$window', 'TabUtil'];

    function AuditController($scope, Dialogs, toastr, VoucherUtils, Audits, systemInfoLoader, AuthServerProvider, GraphQL, $window, TabUtil) {
        var vm = this;
        vm.accessToken = AuthServerProvider.getToken();
        vm.worths = systemInfoLoader.worths;

        vm.tabs = TabUtil.watch($scope, "tab");

        vm.dockets = [];

        vm.markAllChecked = function () {
            vm.dockets.forEach(function (docket) {
                docket.checked = true;
            })
        };

        vm.markAllNotChecked = function () {
            vm.dockets.forEach(function (docket) {
                docket.checked = false;
            })
        };

        vm.valid = function () {
            for (var i = 0; i < vm.dockets.length; i++) {
                if (vm.dockets[i].checked) {
                    return true;
                }
            }
            return false;
        };

        vm.loadList = function () {

            var request = {
                name: "docketsOpen",
                values: ["id", "state", "date",
                    {
                        name: "statsTotal", values: ["count", "total"]
                    },
                    {
                        name: "member", values: ["id", "name"]
                    }
                ]
            }

            GraphQL.then(request, function (result, ctx, headers) {
                vm.dockets = result.docketsOpen;
            })

        };


        vm.closeAudit = function () {
            Dialogs.ask("Prüfung jetzt abschließen?", "Hiermit wird die Prüfung beendet und das Geld freigegeben!").then(function () {
                var dockets = [];
                vm.dockets.forEach(function (docket) {
                    if (docket.checked === true) {
                        dockets.push({id: docket.id});
                    }
                });
                Audits.createAudit(dockets, function (result) {
                    toastr.success("Prüfung abgeschlossen");
                    vm.loadList();
                    Dialogs.ask("Prüfungsprotokoll anzeigen?", "PDF für diese Prüfung anzeigen?").then(function () {
                        $window.location.href = "/api/audits/pdf/" + result.id+"?access_token="+vm.accessToken;
                    });
                }, function(){
                    toastr.error("Prüfung konnte nicht abgeschlossen werden!");
                })
            })

        }





        vm.loadAudits = function () {

            var request = {
                name: "audits",
                variables: {
                    page: vm.audits.page-1,
                    size: vm.audits.size,
                    sort: sortAudits()
                },
                values: ["id", "approved", "date",
                    // {
                    //     name: "statsTotal", values: ["count", "total"]
                    // },
                    {
                        name: "revisor", values: ["id", "name"]
                    }
                ]
            }

            GraphQL.then(request, function (result, ctx, headers) {
                vm.audits.content = result.audits;
                vm.audits.total = headers("X-Total-Count");
            })

        };

        vm.audits = {
            page: 1,
            size: 20,
            content: [],
            total: 0,
            predicate: 'date',
            reverse: false,
            load: vm.loadAudits
        };


        function sortAudits(){
            var result = [vm.audits.predicate + ',' + (vm.audits.reverse ? 'asc' : 'desc')];
            if (vm.audits.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }



        vm.loadList();
        vm.loadAudits();


    }
})();
