(function () {
    'use strict';

    angular
        .module('voucherApp')
        .filter('duration', durationFilter);

    durationFilter.$inject = [];

    function durationFilter() {
        return durationFilter;

        function durationFilter(duration, depth) {
            if (_.isNil(duration)) {
                return duration;
            }

            var dur = moment.duration(duration);
            var outs = [];
            addWhen(dur, outs, "years", "Jahr", "Jahre");
            if(depth==='years'){
                return _.join(outs, ", ");
            }
            addWhen(dur, outs, "months", "Monat", "Monate");
            if(depth==='months'){
                return _.join(outs, ", ");
            }
            addWhen(dur, outs, "days", "Tag", "Tage");
            if(depth==='days'){
                return _.join(outs, ", ");
            }
            addWhen(dur, outs, "hours", "Stunde", "Stunden");
            if(depth==='hours'){
                return _.join(outs, ", ");
            }
            addWhen(dur, outs, "minutes", "Minute", "Minuten");
            if(depth==='minutes'){
                return _.join(outs, ", ");
            }
            addWhen(dur, outs, "seconds", "Sekunde", "Sekunden");
            return _.join(outs, ", ");
        }

        function addWhen(duration, outs, type, singluar, plural){
            var val = duration.get(type);
            if(_.isNumber(val) && val!==Number.NaN && val>0){
                if(val===1) {
                    outs.push("1 "+singluar);
                }else{
                    outs.push(val+" "+plural);
                }
            }
        }
    }
})();
