'use strict';

angular.module('voucherApp')
    .factory('Settings', function ($resource) {
        return $resource('api/settings/:identifier', {}, {
            'get': {
                method: 'GET',
                // transformResponse: function (data) {
                //     data = angular.fromJson(data);
                //     if(data && data.value!==null) {
                //         data.value = JSON.parse(data.value);
                //     }
                //     return data;
                // }
            },
            'save': {
                method: 'PUT',
                transformRequest: function (data) {
                    if(data && data.content){
                        data.content = JSON.stringify(data.content);
                    }
                    return angular.toJson(data);
                }
            }
        });
    });
