'use strict';

angular.module('voucherApp')
    .directive('externalLinkButton', function (AuthServerProvider) {
        return {
            scope: {
                link: '=link'
            },
            templateUrl: 'app/directives/externalLinkButton/externalLinkButton.html',
            restrict: 'EA',
            transclude: true,
            replace: true,
            link: function ($scope, element, $attrs, ngModel) {

                $scope.accessToken = AuthServerProvider.getToken();


            }
        }
    });
