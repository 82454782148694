(function() {
    'use strict';

    angular
        .module('voucherApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('app', {
            abstract: true,
            views: {
                'navbar@': {
                    templateUrl: 'app/layouts/navbar/navbar.html',
                    controller: 'NavbarController',
                    controllerAs: 'vm'
                },
                'navbartop@': {
                    templateUrl: 'app/layouts/navbartop/navbartop.html',
                    controller: 'NavbarTopController',
                    controllerAs: 'vm'
                },
                'content@': {
                    templateUrl: 'app/layouts/content/content.html',
                    controller: 'ContentController'
                }
            },
            resolve: {
                authorize: ['Auth',
                    function (Auth) {
                        return Auth.authorize();
                    }
                ],
                systemInfoLoader: ['systemInfo', function(systemInfo){
                    return systemInfo.resolve();
                }]
            }
        });

        $stateProvider.state('blank', {
            'abstract': true,
            url: '?login_token',
            views: {
                '': {
                    templateUrl: 'app/layouts/blank/blank.html',
                    controller: 'BlankController'
                }
            },
            resolve: {
                authorize: ['Auth', '$stateParams',
                    function (Auth, $stateParams) {
                        return Auth.authorize();
                    }
                ],
                systemInfoLoader: ['systemInfo', function(systemInfo){
                    return systemInfo.resolve();
                }]
            }
        });
    }
})();
