'use strict';

angular.module('voucherApp')
    .factory('Audits', function ($resource) {
        return $resource('api/audits/:id', {}, {

            'getAudits': {
                url: 'api/audits',
                method: 'GET',
                isArray: true
            },
            'createAudit': {
                method: 'POST',
                isArray: false,
                url: 'api/audits'
            },
            'removeAudit': {
                method: 'DELETE',
                isArray: false,
                url: 'api/audits/:id'
            }

        });
    });
