(function () {
    'use strict';

    angular
        .module('voucherApp')
        .controller('MembersDialogController', MembersDialogController);

    MembersDialogController.$inject = ['toastr', '$state', 'Members', 'member', 'GraphQL'];

    function MembersDialogController(toastr, $state, Members, member, GraphQL) {
        var vm = this;
        vm.cancel = cancel;
        vm.save = save;
        vm.member = member;

        vm.docketOrderOptions = [
            {value: true, label: "Abrechnung nach Wert sortiert"},
            {value: false, label: "Abrechnung nach Tag sortiert"}
        ]

        function cancel() {
            $state.go("settings.members");
        }

        function onSaveSuccess(result) {
            vm.isSaving = false;
            toastr.success("Ausgabestelle wurde gespeichert!");
            $state.go("settings.members");
        }

        function onSaveError() {
            vm.isSaving = false;
            toastr.error("Ausgabestelle konnte nicht gespeichert werden!");
        }

        function save() {
            vm.isSaving = true;
            if (!_.isNil(vm.member.id)) {
                Members.update(vm.member, onSaveSuccess, onSaveError);
            } else {
                Members.save(vm.member, onSaveSuccess, onSaveError);
            }
        }
    }
})();
