(function () {
    'use strict';

    angular
        .module('voucherApp')
        .controller('ClearingVouchersController', ClearingVouchersController);


    ClearingVouchersController.$inject = ['$scope', 'Dialogs', 'toastr', 'VoucherUtils', 'Vouchers', 'systemInfoLoader', 'GraphQL', 'Submissions', 'TabUtil', 'Loading', 'Dockets'];

    function ClearingVouchersController($scope, Dialogs, toastr, VoucherUtils, Vouchers, systemInfoLoader, GraphQL, Submissions, TabUtil, Loading, Dockets) {
        var vm = this;


        vm.stock = {};
        vm.lastDockets = [];
        vm.tabs = TabUtil.watch($scope, "tab");


        $scope.$watch("vm.tabs.active", function (newVal) {
            $scope.$emit('bb.tab-changed', {});
        });

        vm.predicate = "id";
        vm.reverse = false;

        vm.list = {
            page: 1,
            size: 20,
            total: 0,
            items: []
        };


        function loadStock() {
            var request = {
                name: "statistics",
                values: [{
                    name: "stockInSubmission", values: [
                        "worth",
                        "count",
                        "value",
                        "min",
                        "minSerial",
                        "max",
                        "maxSerial"]
                },
                    {
                        name: "stockInAudit", values: [
                            "worth",
                            "count",
                            "value",
                            "min",
                            "minSerial",
                            "max",
                            "maxSerial"]
                    }]
            };

            GraphQL.query(request).then(function (response) {
                vm.stock = response.data.statistics;
            });
        }

        function loadMembers() {
            var request = {
                name: "members",
                values: ["id", "name"]
            };
            GraphQL.then(request, function (data) {
                vm.members = data.members;
            });
        }

        function loadLastDockets() {
            var request = {
                name: "dockets",
                variables: {
                    page: 0,
                    size: 10,
                    sort: "id,desc"
                },
                values: ["id", "date", "state",
                    {name: "member", values: ["id", "name"]},
                    {name: "statsTotal", values: ["count", "total"]}
                ]
            };
            GraphQL.then(request, function (data, ctx, headers) {
                vm.lastDockets = data.dockets;
            });
        }


        vm.removeDocket = function (docket, index) {
            Dialogs.ask("Wirklich diese Abrechnung löschen?", "Die darin vorhandenen "+systemInfoLoader.plural+" werden dabei wieder als eingereicht markiert!").then(function () {
                Dockets.deleteDocket({id: docket.id}, function () {
                    toastr.success("Abrechnung gelöscht");
                    vm.loadList();
                }, function () {
                    toastr.error("Diese Abrechnung kann nicht mehr gelöscht werden!");
                })
            })
        };


        vm.loadList = function () {
            var request = {
                name: "dockets",
                variables: {
                    page: vm.list.page - 1,
                    size: 20,
                    sort: sort()
                },
                values: ["id", "date", "state",
                    {name: "member", values: ["id", "name"]},
                    {name: "statsTotal", values: ["count", "total"]}
                ]
            };
            GraphQL.then(request, function (data, ctx, headers) {
                vm.list.items = data.dockets;
                vm.list.total = headers('X-Total-Count');
            });
        };


        function sort() {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }


        vm.resendDockets = function(){
            Dialogs.ask("Sollen nun alle offenen und noch nicht geprüften Abrechnungsbögen neu an die Mitglieder geschickt werden?", "Dies kann etwas Zeit in Anspruch nehmen!").then(function(){
                Loading.open("Sende Abrechnungsbögen erneut...");
                Dockets.resendDockets(function(){
                    toastr.success("Abrechnungsbögen wurden gesendet!");
                    Loading.close();
                }, function () {
                    toastr.error("Abrechnungsbögen konnten nicht gesendet werden!");
                    Loading.close();
                })
            })
        };

        vm.createDockets = function(){
            Dialogs.ask("Sollen nun Abrechnungsbögen für alle offenen Einreichungen erstellten werden und an die Mitglieder geschickt werden?", "Dies kann etwas Zeit in Anspruch nehmen!").then(function(){
                Loading.open("Erstelle Abrechnungsbögen erneut...");
                Dockets.createDockets(function(){
                    toastr.success("Abrechnungsbögen wurden erstellt!");
                    Loading.close();
                }, function () {
                    toastr.error("Abrechnungsbögen konnten nicht erstellt werden!");
                    Loading.close();
                })
            })
        }

        loadMembers();
        loadStock();
        loadLastDockets();
        vm.loadList();


    }
})();
