(function () {
    'use strict';

    angular
        .module('voucherApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('settings.revisors', {
                parent: 'settings',
                url: '/revisors?page&sort',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_AGENT', 'ROLE_SYSTEM']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/views/settings/revisors/revisors.html',
                        controller: 'RevisorsController',
                        controllerAs: 'vm'
                    }
                }, params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'name,asc',
                        squash: true
                    }
                },
                ncyBreadcrumb: {
                    label: 'Prüfer'
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort)
                        };
                    }]
                }
            })
            .state('settings.revisors.new', {
                parent: 'settings.revisors',
                url: '/new',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_AGENT', 'ROLE_SYSTEM']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/views/settings/revisors/dialog/revisors.dialog.html',
                        controller: 'RevisorsDialogController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: 'Neuer Prüfer'
                },
                resolve: {
                    revisor: function () {
                        return {};
                    }
                }
            })
            .state('settings.revisors.edit', {
                parent: 'settings.revisors',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_AGENT', 'ROLE_SYSTEM']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/views/settings/revisors/dialog/revisors.dialog.html',
                        controller: 'RevisorsDialogController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: 'Prüfer bearbeiten'
                },
                resolve: {
                    revisor: ['Revisors', '$stateParams', function (Revisors, $stateParams) {
                        return Revisors.get({id: $stateParams.id}).$promise;
                    }]
                }
            })
            .state('settings.revisors.show', {
                parent: 'settings.revisors',
                url: '/{id}',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_AGENT', 'ROLE_SYSTEM']
                },
                ncyBreadcrumb: {
                    label: 'Prüfer'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/views/settings/revisors/detail/revisors.detail.html',
                        controller: 'RevisorsDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    revisor: ['Revisors', '$stateParams', function (Revisors, $stateParams) {
                        return Revisors.get({id: $stateParams.id}).$promise;
                    }]
                }
            });
    }
})();
