angular.module('voucherApp')
    .service('VoucherUtils', function () {

        this.number = function (completenumber) {
            var str = completenumber.toString();
            str = str.substring(2);
            return parseInt(str);
        };

        this.greaterThan = function(first, second){
            if(this.number(first)>this.number(second)){
                return true;
            }else{
                return false;
            }
        }

        this.transformWorth = function (number) {
            switch(number){
                case "5":
                    return "Five";
                case "10":
                    return "Ten";
                case "25":
                    return "TwentyFive";
                case "44":
                    return "FourtyFour";
                case "50":
                    return "Fifty";
            }
            return null;
        };

        this.worthFormat = function (number) {
            switch(number){
                case "5":
                case 5:
                case "Five":
                    return "5er";
                case "1":
                case 1:
                case "Ten":
                    return "10er";
                case "25":
                case 2:
                case "TwentyFive":
                    return "20er";
                case "44":
                case 3:
                case "FourtyFour":
                    return "44er";
                case "50":
                case 4:
                case "Fifty":
                    return "50er";
            }
            return null;
        };

        this.worthValue = function (number) {
            var trans = this.worthDigit(number);
            switch(trans) {
                case 1:
                    return 10;
                case 2:
                    return 25;
                case 3:
                    return 44;
                case 4:
                    return 50;
                case 5:
                    return 5;
            }
        }

        this.worthDigit = function (number) {
            switch(number){
                case "5":
                case "Five":
                    return 5;
                case "10":
                case "Ten":
                    return 1;
                case "25":
                case "TwentyFive":
                    return 2;
                case "44":
                case "FourtyFour":
                    return 3;
                case "50":
                case "Fifty":
                    return 4;
            }
            return null;
        };

        this.numberToString = function(intval, length){
            if(!length){
                length = 6;
            }
            var r = "" + intval;
            while (r.length < length) {
                r = "0" + r;
            }
            return r;
        }
    }
);
