'use strict';

angular.module('voucherApp')
    .directive('voucherStatistics', function (AuthServerProvider) {
        return {
            scope: {
                stats: '=voucherStatistics'
            },
            templateUrl: 'app/directives/voucherStatistics/voucherStatistics.html',
            restrict: 'EA',
            transclude: true,
            replace: true,
            link: function ($scope, element, $attrs, ngModel) {

                $scope.total = 0;
                $scope.totalCount = 0;
                $scope.$watch('stats', function (newVal) {
                    $scope.total = 0;
                    $scope.totalCount = 0;
                    if(!_.isNil(newVal)) {
                        $scope.stats.forEach(function (s) {
                            $scope.total = $scope.total + s.value;
                            $scope.totalCount = $scope.totalCount + s.count;
                        })
                    }
                }, true);

            }
        }
    });
