'use strict';

angular.module('voucherApp')
    .controller('BlankController', function ($scope, $state) {

        // $scope.openMenu = function () {
        //     var options= {
        //         title: "Was möchten Sie machen?",
        //         confirmButtonText: "Zum Hauptmenü",
        //         noButtonText: "Einstellungen anzeigen"
        //     }
        //     Dialogs.open(options).then(function(result){
        //         if(result) {
        //             $state.go("home")
        //         }else{
        //             alert("Show settings...");
        //         }
        //     })
        // }
    });
