/*jshint bitwise: false*/
'use strict';

angular.module('voucherApp')
    .service('systemInfo', function ($q, $timeout, $http) {
        var type = {
            singular: "",
            plural: "",
            contactURL: "",
            logoImage: "",
            tenant: "",
            worths: []
        };


        this.resolve = function () {
            var deferred = $q.defer();

            $http.get("/api/system-info").then(function (result) {
                var info = result.data;
                type.singular = info.nameSingular;
                type.plural = info.namePlural;
                type.contactURL = info.contactURL;
                type.logoImage = info.logoImage;
                type.tenant = info.tenant;
                type.worths = info.worths;
                deferred.resolve(type);
            });
            return deferred.promise;
        };

        this.contactURL = function () {
            return type.contactURL;
        };

        this.logoImage = function () {
            return type.logoImage;
        };

        this.type = function () {
            return type;
        };

        this.tenant = function () {
            return type.tenant;
        };

        this.worths = function () {
            return type.worths;
        }

        this.name = function (count) {
            if (!count) {
                return type.plural;
            }
            if (count === 1) {
                return type.singular;
            } else {
                return type.plural;
            }
        };

        this.singular = function () {
            return type.singular;
        };

        this.plural = function () {
            return type.plural;
        };
    })

