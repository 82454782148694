(function () {
    'use strict';

    angular
        .module('voucherApp')
        .controller('RevisorsDialogController', RevisorsDialogController);

    RevisorsDialogController.$inject = ['toastr', '$state', 'Revisors', 'revisor', 'GraphQL'];

    function RevisorsDialogController(toastr, $state, Revisors, revisor, GraphQL) {
        var vm = this;
        vm.cancel = cancel;
        vm.save = save;
        vm.revisor = revisor;

        function cancel() {
            $state.go("settings.revisors");
        }

        function onSaveSuccess(result) {
            vm.isSaving = false;
            toastr.success("Ausgabestelle wurde gespeichert!");
            $state.go("settings.revisors");
        }

        function onSaveError() {
            vm.isSaving = false;
            toastr.error("Ausgabestelle konnte nicht gespeichert werden!");
        }

        function save() {
            vm.isSaving = true;
            if (!_.isNil(vm.revisor.id)) {
                Revisors.update(vm.revisor, onSaveSuccess, onSaveError);
            } else {
                Revisors.save(vm.revisor, onSaveSuccess, onSaveError);
            }
        }
    }
})();
