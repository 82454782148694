(function () {
    'use strict';

    angular
        .module('voucherApp')
        .controller('UsersDialogController', UsersDialogController);

    UsersDialogController.$inject = ['toastr', '$state', 'entity', 'User', 'Members', 'GraphQL', 'Dialogs', '$q', 'Loading'];

    function UsersDialogController(toastr, $state, entity, User, Members, GraphQL, Dialogs, $q, Loading) {
        var vm = this;
        vm.cancel = cancel;
        vm.save = save;
        vm.generatePassword = generatePassword;
        vm.user = entity;


        function loadData() {
            var request = [
                {name: "members", values: ["id", "name"]},
                {name: "revisors", values: ["id", "name"]},
                {name: "issuers", values: ["id", "name"]}
            ];
            GraphQL.query(request).then(function (result) {
                vm.members = result.data.members;
                vm.revisors = result.data.revisors;
                vm.issuers = result.data.issuers;
            })
        }


        loadData();

        function cancel() {
            $state.go("settings.users");
        }


        function onSaveError(error) {
            vm.isSaving = false;
            if(error && error.data && error.data.exception){
                if(_.includes(error.data.exception, "LoginAlreadyUsedException")){
                    toastr.error("Ein Benutzer mit diesem Login existiert bereits!");
                }else{
                    toastr.error("Benutzer konnte nicht gespeichert werden!");
                }
            }else {
                toastr.error("Benutzer konnte nicht gespeichert werden!");
            }
        }

        function generatePassword() {
            var chars = "ABCDEFGHJKMNOPQRST";
            var rnum = Math.floor(Math.random() * chars.length);
            var schar = chars.charAt(rnum);
            var password = Math.random()
                .toString(36)
                .slice(-8);
            var tonum = Math.floor(Math.random() * 6) + 1;
            password = password.substring(0, tonum) + schar + password.substring(tonum, password.length)
            if (!vm.user) {
                vm.user = {};
            }
            vm.user.password = password;
            vm.confirmPassword = password;
            console.log(password);
        }


        function sendWelcomeMail(savedUser){
            var deferred = $q.defer();
            Loading.open("Sende Willkommens-Email...");
            User.sendWelcomeMail({id: savedUser.id}, savedUser, function(){
                Loading.close();
                toastr.success("Email wurde erfolgreich versandt");
                deferred.resolve();

            }, function(){
                Loading.close();
                toastr.error("Email konnte nicht versandt werden");
                deferred.reject();
            });

            return deferred.promise;
        }



        vm.resetPasswort = function(){
            Dialogs.ask("Jetzt ein neues Passwort erstellen lassen und die Willkommens-Email erneut senden?").then(function(){
                Loading.open("Erstelle neues Passwort und sende Willkommens-Email...");
                User.resendWelcomeMail({id: vm.user.id}, function(){
                    Loading.close();
                    toastr.success("Email wurde erfolgreich versandt", "Zurücksetzen erfolgreich");

                }, function(){
                    Loading.close();
                    toastr.error("Email konnte nicht versandt werden");
                });
            })
        }

        function save() {
            vm.isSaving = true;
            console.log(vm.user.id);
            if (!_.isNil(vm.user.id)) {
                User.update(vm.user, function(){
                    vm.isSaving = false;
                    toastr.success("Benutzer wurde gespeichert!");
                    $state.go("settings.users");
                }, onSaveError);
            } else {
                vm.user.langKey = 'de';
                User.save(vm.user, function(result){

                    vm.isSaving = false;
                    toastr.success("Benutzer wurde gespeichert!");
                    Dialogs.ask("Willkommens-Email senden?", "Soll dem Nutzer eine Willkommens-Email mit den Zugangsdaten geschickt werden?").then(function(){
                        var savedUser = angular.copy(vm.user);
                        savedUser.id = result.id;
                        sendWelcomeMail(savedUser).then(function(){
                            $state.go("settings.users");
                        })
                    }, function(){
                        $state.go("settings.users");
                    })



                }, onSaveError);
            }
        }
    }
})();
