(function () {
    'use strict';

    angular
        .module('voucherApp')
        .controller('ResetFinishController', ResetFinishController);

    ResetFinishController.$inject = ['$stateParams', '$timeout', 'Auth', 'systemInfo'];

    function ResetFinishController($stateParams, $timeout, Auth, systemInfo) {
        var vm = this;

        vm.keyMissing = angular.isUndefined($stateParams.key);
        vm.error = false;
        vm.success = false;
        vm.doNotMatch = false;


        vm.label = systemInfo.type();
        vm.finishReset = finishReset;

        vm.resetAccount = {};
        vm.confirmPassword = null;


        $timeout(function () {
            angular.element('#password').focus();
        });

        function finishReset() {
            vm.doNotMatch = false;
            vm.error = false;
            vm.success = false;

            if (vm.resetAccount.password !== vm.confirmPassword) {
                vm.doNotMatch = true;
            } else {
                Auth.resetPasswordFinish({key: $stateParams.key, newPassword: vm.resetAccount.password}).then(function (result) {
                    vm.success = true;
                }).catch(function (error) {
                    vm.success = false;
                    vm.error = true;
                });
            }
        }
    }
})();
