(function () {
    'use strict';

    angular
        .module('voucherApp')
        .controller('AgbController', AgbController);

    AgbController.$inject = ['Settings', 'Account', 'toastr', '$state', 'Principal'];

    function AgbController(Settings, Account, toastr, $state, Principal) {
        var vm = this;

        vm.needsConfirm = false;

        Settings.get({identifier: "general_agbs"}, function (result) {
            vm.agb = result.value.replace(/\\n/g, "<br />");
        });

        Account.needsAGBs(function (response) {
            vm.needsConfirm = response.result;
        })


        vm.acceptAGBs = function () {
            Account.acceptAGBs(function (response) {
                toastr.success("AGBs wurden akzeptiert!");
                Principal.identity(true).then(function(){
                    $state.go("home");
                })

            }, function(){
                toastr.error("Fehler beim Akzeptieren der AGBs");
            })
        }

    }
})();
