'use strict';

angular.module('voucherApp')
    .factory('Vouchers', function ($resource) {
        return $resource('api/vouchers/:id', {}, {

            'getByState': {
                method: 'GET',
                isArray: true,
                url: 'api/vouchers/:state'
            },
            'getExpired': {
                method: 'GET',
                isArray: true,
                url: 'api/vouchers/expired/list'
            },
            'checkDigit': {
                method: 'GET',
                url: 'api/vouchers/checkdigit/:number'
            },
            'lastGenerated': {
                method: 'GET',
                url: 'api/vouchers/last/:worth'
            },
            'generate': {
                method: 'GET',
                isArray: true,
                url: 'api/vouchers/generate/:worth/:from/:to'
            },
            'validate': {
                method: 'GET',
                isArray: false,
                url: 'api/vouchers/validate/:number'
            },
            'countByState':{
                method: 'GET',
                isArray: false,
                url: 'api/vouchers/count/:worth/:from/:to/:state'
            },
            'destroyVoucher': {
                method: 'DELETE',
                isArray: false,
                url: 'api/vouchers/destroy/:id'
            }


        });
    });
