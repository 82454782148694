(function () {
    'use strict';

    angular
        .module('voucherApp')
        .controller('StockDealOutDialogController', StockDealOutDialogController);


    StockDealOutDialogController.$inject = ['$scope', 'Dialogs', 'toastr', 'VoucherUtils', 'Vouchers', 'systemInfoLoader', 'issuer', 'Loading', '$state', 'GraphQL', '$q', 'DealOuts'];

    function StockDealOutDialogController($scope, Dialogs, toastr, VoucherUtils, Vouchers, systemInfoLoader, issuer, Loading, $state, GraphQL, $q, DealOuts) {
        var vm = this;
        vm.worths = systemInfoLoader.worths;


        vm.dealOut = [];
        vm.summary = [];
        vm.worths.forEach(function (worth) {
            vm.dealOut.push({worth: worth, validStart: false, validEnd: false, invalidRange: false});
            vm.summary.push({worth: worth.worth, minSerial: null, maxSerial: null, count: 0, value: 0, worthValue: worth.value});
        });

        vm.issuer = issuer;


        function loadStats() {

            var request = {
                name: "issuer",
                variables: {id: vm.issuer.id},
                values: [
                    "name",
                    {name: "statsIn", values: ["...stats"]},
                    {name: "statsStock", values: ["...stats"]},
                    {name: "statsOut", values: ["...stats"]}
                ],
                fragments: [
                    {name: "stats", on: "VoucherStat", values: ["worth", "value", "count", "minSerial", "maxSerial"]}
                ]
            };

            GraphQL.then(request, function (data) {
                vm.stats = data.issuer;
            })

        }


        function loadDealOuts() {

            var request = {
                name: "issuer",
                variables: {id: vm.issuer.id},
                values: [
                    "name",
                    {name: "dealOuts", values: ["id", "dealOutAt", "dealOutBy"]}
                ]
            };

            GraphQL.then(request, function (data) {
                vm.dealOuts = data.issuer.dealOuts;
            })

        }


        loadStats();
        loadDealOuts();

        vm.searchVouchers = function (worth, filter) {

            var request = {
                name: "vouchersSearch",
                variables: {worth: worth, state: 'InDepot', filter: filter, limit: 20},
                values: ["id", "number", "worth", "serialString", "worthLabel"]
            };
            var deferred = $q.defer();
            GraphQL.then(request, function (data) {
                deferred.resolve(data.vouchersSearch);
            });
            return deferred.promise;
        };


        $scope.$watch("vm.dealOut", function (newVal) {
            vm.dealOut.forEach(function (dealOut) {
                    dealOut.invalidRange = false;
                    var count = 0;
                    var startSerial = null;
                    var endSerial = null;
                    if (_.isNil(dealOut.start) || _.isNil(dealOut.start.serialString)) {
                        count = 0;
                    } else {
                        if (_.isNil(dealOut.end) || _.isNil(dealOut.end.serialString)) {
                            count = 0;
                        } else {

                            if (dealOut.start.number <= dealOut.end.number) {
                                count = dealOut.end.number - dealOut.start.number + 1;
                                startSerial = dealOut.start.serialString;
                                endSerial = dealOut.end.serialString;
                            } else {
                                dealOut.invalidRange = true;
                            }
                        }
                    }
                    vm.summary.forEach(function (sum) {
                        if (sum.worth === dealOut.worth.worth) {
                            sum.count = count;
                            sum.minSerial = startSerial;
                            sum.maxSerial = endSerial;
                            sum.value = dealOut.worth.value * count;
                        }
                    })
                }
            );

        }, true);


        vm.invalid = function () {
            if (_.isNil(vm.summary)) {
                return true;
            }
            var valids = 0;
            for (var i = 0; i < vm.dealOut.length; i++) {
                var deal = vm.dealOut[i];
                if (!_.isNil(deal.start) && !_.isNil(deal.start.serialString)) {
                    if (!_.isNil(deal.end) && !_.isNil(deal.end.serialString)) {
                        if (!deal.invalidRange) {
                            valids++;
                        }
                    }
                }
            }
            if (valids === 0) {
                return true;
            }
            return false;
        };


        vm.submit = function () {

            Dialogs.ask("Jetzt " + systemInfoLoader.plural + " an " + vm.issuer.name + " ausgeben?").then(function () {
                Loading.open(systemInfoLoader.plural + " werden ausgegeben...");
                var deal = {
                    issuer: vm.issuer,
                    worths: []
                };
                vm.dealOut.forEach(function (dealOut) {
                    deal.worths.push({
                        worth: dealOut.worth.worth,
                        start: dealOut.start,
                        end: dealOut.end
                    });
                });

                DealOuts.create(deal, function () {
                    toastr.success(systemInfoLoader.plural + " wurden ausgegeben");
                    Loading.close();
                    $state.go("stock-dealout");

                }, function (result) {
                    toastr.error(systemInfoLoader.plural + " konnten nicht ausgegeben werden!", result.data.message);
                    Loading.close();
                })
            })
        }


        vm.clearCompleteIssuer = function(){
            Dialogs.ask("Wirklich den gesamten Bestand für diese Ausgabestelle deaktivieren?", "Die "+systemInfoLoader.plural+" werden dann als \"vernichtet\" markiert!").then(function(){
                Loading.open("Deaktiviere Bestand...");
                DealOuts.clearDealOutsIssuer({issuerId: vm.issuer.id}, function(){
                    toastr.success("Bestand wurde gelöscht!");
                    Loading.close();
                    loadStats();
                }, function(){
                    toastr.error("Bestand konnte nicht gelöscht werden");
                    Loading.close();
                })
            })
        }



        // vm.updateValues();


    }
})();
