(function () {
    /*jshint bitwise: false*/
    'use strict';

    angular
        .module('voucherApp')
        .factory('GraphQLSocket', GraphQLSocket);

    GraphQLSocket.$inject = ['$rootScope', '$q', '$http', '$websocket'];

    function GraphQLSocket($rootScope, $q, $http, $websocket) {

        var messageCounter = 1;
        var reconnect = true;
        var stateWaitConnectionAck = false;
        var stateIsOpen = false;
        var stateConnected = false;
        var dataStream;
        var subscriptions = [];
        var connectionWaiter = $q.defer();
        var waitingSubscribers = [];

        var service = {
            initialize: initialize,
            subscribe: subscribe,
            unsubscribe: unsubscribe
        };

        return service;


        function initialize() {
            waitingSubscribers = [];
            stateConnected = false;
            var loc = window.location;
            var url = 'ws://' + loc.host + loc.pathname + 'graphql-subscriptions';
            dataStream = $websocket(url, {reconnectIfNotNormalClose: false});
            dataStream.onMessage(handleMessage);
            dataStream.onOpen(function () {
                stateIsOpen = true;
                sendConnectionInit(dataStream);
            });

            dataStream.onClose(function(){
                console.log("graph subscriptions socket closed");
            })

            dataStream.onError(function(){
                console.log("graph subscriptions socket error");
            })
        }


        function subscribe(query, callback) {
            var deferred = $q.defer();
            if(stateConnected){
                doSubscribe(query, callback, deferred);
            }else{
                waitingSubscribers.push({
                    query: query,
                    callback: callback,
                    promise: deferred
                });
            }
            return deferred.promise;
        }

        function doSubscribe(query, callback, deferred){
            var id = "" + (messageCounter++);
            subscriptions[id] = callback;
            //send start
            if (stateConnected) {
                sendStart(id, query);
                deferred.resolve(id);
            } else {
               console.error("Socket is not connected!");
            }

            return deferred.promise;
        }


        function sendStart(id, query, variables) {
            var data = {
                id: id,
                type: "start",
                payload: {
                    query: query,
                    variables: variables
                }
            };
            dataStream.send(JSON.stringify(data));
        }


        function connectWaiting(){

            waitingSubscribers.forEach(function(sub){
                doSubscribe(sub.query, sub.callback, sub.promise);
            });

            waitingSubscribers = [];


        }

        function unsubscribe(id){
            if(!_.isNil(subscriptions[id])) {
                sendStop(id);
                delete subscriptions[id];
            }
        }

        function sendStop(id) {
            var data = {
                id: id,
                type: "stop"
            };
            dataStream.send(JSON.stringify(data));
        }

        function sendConnectionInit(dataStream) {
            var id = "" + (messageCounter++);
            var data = {
                id: id,
                type: "connection_init",
                payload: null
            };
            dataStream.send(JSON.stringify(data));
            stateWaitConnectionAck = true;
        }


        function handleMessage(rawData) {
            var message = JSON.parse(rawData.data);
            if (!stateIsOpen) {
                return;
            }
            if (stateWaitConnectionAck) {
                if (message.type === "connection_ack") {
                    stateWaitConnectionAck = false;
                    stateConnected = true;
                    connectWaiting();
                }
            }
            if (stateConnected) {
                if (message.type === "data") {
                    if (message.id) {
                        var cb = subscriptions[message.id];
                        if (cb) {
                            cb(message.payload);
                        }
                    }
                }
            }
        }


    }
})();
