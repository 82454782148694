(function() {
    'use strict';

    angular
        .module('voucherApp')
        .controller('MembersDetailController', MembersDetailController);

    MembersDetailController.$inject = ['$stateParams', 'member'];

    function MembersDetailController($stateParams, member) {
        var vm = this;

        vm.member = member;
    }
})();
