'use strict';

angular.module('voucherApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('stock-dealout', {
                parent: 'app',
                url: '/stock-dealout',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SYSTEM', 'ROLE_AGENT']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/views/stock-dealout/stock-dealout.html',
                        controller: 'StockDealOutController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: 'Ausgaben an Ausgabestelle'
                }
            })

            .state('stock-dealout-issuer', {
                parent: 'stock-dealout',
                url: '/{id:int}',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SYSTEM', 'ROLE_AGENT']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/views/stock-dealout/dialog/stock-dealout.dialog.html',
                        controller: 'StockDealOutDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    issuer: ['$stateParams', 'Issuers', function ($stateParams, Issuers) {
                        return Issuers.get({id: $stateParams.id}).$promise;
                    }]
                },
                ncyBreadcrumb: {
                    label: 'An Ausgabestelle ausgeben'
                }
            })

            .state('stock-dealout-detail', {
                parent: 'stock-dealout',
                url: '/detail/{id:int}',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SYSTEM', 'ROLE_AGENT']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/views/stock-dealout/detail/stock-dealout.detail.html',
                        controller: 'StockDealOutDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    dealOut: ['$stateParams', 'DealOuts', function ($stateParams, DealOuts) {
                        return DealOuts.get({id: $stateParams.id}).$promise;
                    }]
                },
                ncyBreadcrumb: {
                    label: 'Ausgabe'
                }
            })
    });
