(function () {
    'use strict';

    angular
        .module('voucherApp')
        .factory('Auth', Auth);

    Auth.$inject = ['$rootScope', '$state', '$sessionStorage', '$q', 'Principal', 'AuthServerProvider', 'Account', 'Register', 'Activate', 'Password', 'PasswordResetInit', 'PasswordResetFinish'];

    function Auth($rootScope, $state, $sessionStorage, $q, Principal, AuthServerProvider, Account, Register, Activate, Password, PasswordResetInit, PasswordResetFinish) {
        var service = {
            activateAccount: activateAccount,
            authorize: authorize,
            changePassword: changePassword,
            createAccount: createAccount,
            getPreviousState: getPreviousState,
            login: login,
            logout: logout,
            loginWithToken: loginWithToken,
            resetPasswordFinish: resetPasswordFinish,
            resetPasswordInit: resetPasswordInit,
            resetPreviousState: resetPreviousState,
            storePreviousState: storePreviousState,
            updateAccount: updateAccount
        };

        return service;

        function activateAccount(key, callback) {
            var cb = callback || angular.noop;

            return Activate.get(key,
                function (response) {
                    return cb(response);
                },
                function (err) {
                    return cb(err);
                }.bind(this)).$promise;
        }

        function authorize(force) {
            var authReturn = Principal.identity(force).then(authThen, authThen);

            return authReturn;

            function authThen() {
                var validLicence = true;

                var isAuthenticated = Principal.isAuthenticated();
                var needsAccount = Principal.needsAccount();
                var needsAGB = Principal.needsAGB();
                if (validLicence) {
                    // an authenticated user can't access to login and register pages
                    if (isAuthenticated && $rootScope.toState.name === 'login') {
                        if (needsAGB) {
                            $state.go('account.agb');
                        } else {
                            if (needsAccount) {
                                $state.go('account.account');
                            } else {
                                $state.go('home');
                            }
                        }

                    }

                    // recover and clear previousState after external login redirect (e.g. oauth2)
                    if (isAuthenticated && !$rootScope.fromState.name && getPreviousState()) {
                        var previousState = getPreviousState();
                        resetPreviousState();

                        $state.go(previousState.name, previousState.params);
                    }

                    if ($rootScope.toState && $rootScope.toState.data.authorities && $rootScope.toState.data.authorities.length > 0 && !Principal.hasAnyAuthority($rootScope.toState.data.authorities)) {
                        if (isAuthenticated) {
                            // user is signed in but not authorized for desired state
                            $state.go('accessdenied');
                        } else {

                            if ($rootScope.toStateParams && $rootScope.toStateParams.login_token) {
                                storePreviousState($rootScope.toState.name, $rootScope.toStateParams);
                                $state.go("autologin", {token: $rootScope.toStateParams.login_token})

                            } else {
                                // user is not authenticated. store the state they wanted before you
                                // send them to the login service, so you can return them when you're done
                                storePreviousState($rootScope.toState.name, $rootScope.toStateParams);

                                // now, send them to the signin state so they can log in
                                $state.go('login');
                            }
                        }
                    } else {

                        if(isAuthenticated) {
                            if (needsAGB) {
                                if (!($rootScope.toState && $rootScope.toState.name === 'account.agb')) {
                                    $state.go('account.agb');
                                }
                            } else {
                                if (needsAccount) {
                                    if (!($rootScope.toState && $rootScope.toState.name === 'account.banking')) {
                                        $state.go('account.banking');
                                    }
                                }
                            }
                        }
                        // if(needsAGB){
                        //     $state.go('account.agb');
                        // }else{
                        //     if(needsAccount){
                        //         $state.go('account.account');
                        //     }else{
                        //         // allow
                        //     }
                        // }
                    }

                } else {
                    // no valid licence
                    if (isAuthenticated) {
                        if ($rootScope.toState && $rootScope.toState.name === 'licence') {
                            // do nothing, when we already go to licence page
                        } else {
                            toastr.warning("Lizenz ist abgelaufen");
                            $state.go('licence');
                        }
                    } else {
                        if (!($rootScope.toState && $rootScope.toState.name === 'login')) {
                            // user is not authenticated. store the state they wanted before you
                            // send them to the login service, so you can return them when you're done
                            storePreviousState($rootScope.toState.name, $rootScope.toStateParams);

                            // now, send them to the signin state so they can log in
                            $state.go('login');
                        }
                    }
                }
            }
        }

        function changePassword(newPassword, callback) {
            var cb = callback || angular.noop;

            return Password.save(newPassword, function () {
                return cb();
            }, function (err) {
                return cb(err);
            }).$promise;
        }

        function createAccount(account, callback) {
            var cb = callback || angular.noop;

            return Register.save(account,
                function () {
                    return cb(account);
                },
                function (err) {
                    this.logout();
                    return cb(err);
                }.bind(this)).$promise;
        }

        function login(credentials, callback) {
            var cb = callback || angular.noop;
            var deferred = $q.defer();

            AuthServerProvider.login(credentials)
                .then(loginThen)
                .catch(function (err) {
                    this.logout();
                    deferred.reject(err);
                    return cb(err);
                }.bind(this));

            function loginThen(data) {
                Principal.identity(true).then(function (account) {
                    deferred.resolve(data);
                });
                return cb();
            }

            return deferred.promise;
        }

        function loginWithToken(jwt, rememberMe) {
            return AuthServerProvider.loginWithToken(jwt, rememberMe);
        }

        function logout() {
            AuthServerProvider.logout();
            Principal.authenticate(null);
        }

        function resetPasswordFinish(keyAndPassword, callback) {
            var cb = callback || angular.noop;

            return PasswordResetFinish.save(keyAndPassword, function () {
                return cb();
            }, function (err) {
                return cb(err);
            }).$promise;
        }

        function resetPasswordInit(mail, callback) {
            var cb = callback || angular.noop;

            return PasswordResetInit.save(mail, function () {
                return cb();
            }, function (err) {
                return cb(err);
            }).$promise;
        }

        function updateAccount(account, callback) {
            var cb = callback || angular.noop;

            return Account.save(account,
                function () {
                    return cb(account);
                },
                function (err) {
                    return cb(err);
                }.bind(this)).$promise;
        }

        function getPreviousState() {
            var previousState = $sessionStorage.previousState;
            return previousState;
        }

        function resetPreviousState() {
            delete $sessionStorage.previousState;
        }

        function storePreviousState(previousStateName, previousStateParams) {
            var previousState = {"name": previousStateName, "params": previousStateParams};
            $sessionStorage.previousState = previousState;
        }
    }
})();
