'use strict';

angular.module('voucherApp')
    .factory('Issuers', function ($resource) {
        return $resource('api/issuers/:id', {}, {
            'query': {method: 'GET', isArray: true},
            'get': {method: 'GET'},
            'save': {method: 'POST'},
            'update': {method: 'PUT'},
            'delete': {method: 'DELETE'},
            'getSales': {
                url: 'api/issuers/:id/sales',
                method: 'GET',
                isArray: true
            },
            'deleteSale': {
                url: 'api/issuers/:id/sales/:saleId',
                method: 'DELETE',
                isArray: false
            }

        });
    });
