'use strict';

angular.module('voucherApp')
    .directive('ibCheck', function ($timeout) {
        return {
            scope: {
                model: '=ibCheck',
                color: '@',
                onChange: '&?'
            },
            templateUrl: 'app/directives/ibCheck/ibCheck.html',
            restrict: 'EA',
            transclude: true,
            replace: true,
            link: function ($scope, element, $attrs, ngModel) {


                return $timeout(function() {
                    if(!$scope.color){
                        $scope.color = 'blue';
                    }
                    var value = $attrs['value'];
                    var inputElement = element.find('input');

                    $scope.$watch('model', function(newValue){
                        $(element).iCheck('update');
                        if ($scope.onChange) {
                            var map = {
                                "value": newValue
                            };
                            $scope.onChange(map);
                        }
                    });

                    return $(element).iCheck({
                        checkboxClass: 'ib-check-container icheckbox_square-'+$scope.color,
                        radioClass: 'ib-check-container iradio_square-'+$scope.color

                    }).on('ifChanged', function(event) {
                        if ($(inputElement).attr('type') === 'checkbox') {
                            $scope.$apply(function() {
                                $scope.model = event.target.checked;
                            });
                        }
                        if ($(inputElement).attr('type') === 'radio') {
                            return $scope.$apply(function() {
                                $scope.model = value;
                            });
                        }
                    });
                });
            }
        }
    });
