'use strict';

angular.module('voucherApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('clearing-vouchers', {
                parent: 'app',
                url: '/clearing-vouchers?tab',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SYSTEM', 'ROLE_AGENT', 'ROLE_MEMBER', 'ROLE_REVISOR']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/views/clearing-vouchers/clearing-vouchers.html',
                        controller: 'ClearingVouchersController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: 'Abrechnungen'
                },
                params: {
                    tab: {
                        value: "0"
                    }
                },
                reloadOnSearch: false
            })

            .state('clearing-vouchers-member', {
                parent: 'clearing-vouchers',
                url: '/{id}?dialog-tab',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SYSTEM', 'ROLE_AGENT', 'ROLE_MEMBER', 'ROLE_REVISOR']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/views/clearing-vouchers/dialog/clearing-vouchers.dialog.html',
                        controller: 'ClearingVouchersDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    member: ['$stateParams', 'Members', function ($stateParams, Members) {
                        return Members.get({id: $stateParams.id}).$promise;
                    }]
                },
                ncyBreadcrumb: {
                    label: 'Abrechnungen'
                },
                params: {
                    'dialog-tab': {
                        value: "0"
                    }
                },
                reloadOnSearch: false
            })

            .state('clearing-vouchers-detail', {
                parent: 'clearing-vouchers',
                url: '/detail/{id:int}',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SYSTEM', 'ROLE_AGENT', 'ROLE_MEMBER', 'ROLE_REVISOR']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/views/clearing-vouchers/detail/clearing-vouchers.detail.html',
                        controller: 'ClearingVouchersDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    docket: ['$stateParams', 'Dockets', function ($stateParams, Dockets) {
                        return Dockets.get({id: $stateParams.id}).$promise;
                    }]
                },
                ncyBreadcrumb: {
                    label: 'Abrechnung'
                }
            })
    });
