'use strict';

angular.module('voucherApp')
    .directive('chartPie', function ($filter, $rootScope, $timeout) {
        return {
            scope: {
                data: '=chartData',
                prop: '@chartProp',
                chartHeight: '@?',
                chartWidth: '@?'
            },
            templateUrl: 'app/directives/chartPie/chartPie.html',
            restrict: 'EA',
            replace: true,
            link: function ($scope, element, $attrs, ngModel) {

                var chart = null;
                var height = "270px";
                if(!_.isNil($scope.chartHeight)){
                    height = $scope.chartHeight;
                }
                var width = "300px";
                if(!_.isNil($scope.chartWidth)){
                    width = $scope.chartWidth;
                }

                if (_.isNil($scope.prop)) {
                    $scope.prop = "value";
                }

                var baseContainer = $(element).find('.metric-chart-base-container');

                var containerId = "chart-container-" + makeId();
                var containerDiv = angular.element('<div id="' + containerId + '" style="height:' + height + '; width: 100%; min-width: '+width+'; min-height: '+height+'"></div>');
                baseContainer.append(containerDiv);


                function makeId() {
                    var text = "";
                    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

                    for (var i = 0; i < 10; i++)
                        text += possible.charAt(Math.floor(Math.random() * possible.length));

                    return text;
                }

                function buildChart() {
                    var options = {
                        tooltip: {
                            trigger: 'item',
                            formatter: "{b}: {c} ({d}%)"
                        },
                        series: [{
                            name: 'Aktueller Bestand',
                            type: 'pie',
                            radius: '55%',
                            center: ['50%', '60%'],
                            data: [],
                            itemStyle: {
                                emphasis: {
                                    shadowBlur: 10,
                                    shadowOffsetX: 0,
                                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                                }
                            }
                        }]
                    }
                    if (chart == null) {
                        chart = echarts.init(document.getElementById(containerId));


                    }

                    if (!_.isNil($scope.data) && _.isArrayLike($scope.data)) {
                        $scope.data.forEach(function (data) {
                            options.series[0].data.push({value: data[$scope.prop], name: $filter('worth')(data.worth)})
                        })
                    }

                    setTimeout(function () {
                        chart.setOption(options);
                        chart.resize();
                    }, 100);

                }

                buildChart();

                $(window).on('resize', function () {
                    if (!_.isNil(chart)) {
                        chart.resize();
                    }
                });

                $rootScope.$on('bb.tab-changed', function () {
                    if (!_.isNil(chart)) {
                        $timeout(function () {
                            chart.resize();
                        }, 0);
                    }
                });


                $scope.$watch("data", function (newVal) {
                    $timeout(function () {
                        buildChart();
                    }, 200);
                })
            }
        }
    });
