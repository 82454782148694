(function () {
    'use strict';

    angular
        .module('voucherApp')
        .controller('BookOutController', BookOutController);


    BookOutController.$inject = ['$scope', 'Dialogs', 'toastr', 'VoucherUtils', 'systemInfoLoader', 'AuthServerProvider', 'GraphQL', 'TabUtil', 'Vouchers', 'BookOuts'];

    function BookOutController($scope, Dialogs, toastr, VoucherUtils, systemInfoLoader, AuthServerProvider, GraphQL, TabUtil, Vouchers, BookOuts) {
        var vm = this;
        vm.accessToken = AuthServerProvider.getToken();
        vm.worths = systemInfoLoader.worths;

        vm.tabs = TabUtil.watch($scope, "tab");

        vm.list = {
            page: 1,
            size: 50,
            total: 0,
            items: []
        };

        vm.bookouts = {
            predicate:"id",
            reverse: false,
            page: 1,
            size: 50,
            total: 0,
            items: []
        };


        vm.loadList = function () {
            Vouchers.getExpired({page: vm.list.page - 1, size: vm.list.size, sort: sort()}, function (result, headers) {
                vm.list.total = headers('X-Total-Count');
                vm.list.items = result;
            });
        };

        function sort() {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }

        vm.loadList();


        vm.loadBookouts  = function () {
            var request = {
                name: "bookOuts",
                variables: {
                    page: vm.bookouts.page - 1,
                    size: vm.bookouts.size,
                    sort: sortBookouts()
                },
                values: ["id", "bookdate", "name",
                    {name: "statsTotal", values: ["count", "total"]}
                ]
            };
            GraphQL.then(request, function (data, ctx, headers) {
                vm.bookouts.items = data.bookOuts;
                vm.bookouts.total = headers('X-Total-Count');
            });
        };

        function sortBookouts() {
            var result = [vm.bookouts.predicate + ',' + (vm.bookouts.reverse ? 'asc' : 'desc')];
            if (vm.bookouts.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }


        vm.loadBookouts();

        vm.doBookOut = function () {
            Dialogs.ask("Alle Werte, die abgelaufen sind, nun ausbuchen?", "Diese sind dann nicht mehr im Umlauf und können auch nicht mehr eigereicht werden!").then(function () {
                BookOuts.doBookOut(function () {
                    toastr.success("Erfolgreich ausgebucht!");
                    vm.loadList();
                    vm.loadBookouts();
                }, function () {
                    toastr.error("Fehler beim ausbuchen!");
                });
            });
        };


    }
})();
