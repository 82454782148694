'use strict';

angular.module('voucherApp')
    .factory('BookOuts', function ($resource) {
        return $resource('api/book-outs/:id', {}, {

            'doBookOut': {
                method: 'GET',
                isArray: false,
                url: 'api/book-outs/do-book-out'
            },
            'getDealOuts': {
                method: 'GET',
                isArray: true,
                url: 'api/book-outs'
            },
            'getDealOut': {
                method: 'GET',
                isArray: false,
                url: 'api/book-outs/:id'
            }
        });
    });
