(function () {
    'use strict';

    angular
        .module('voucherApp')
        .filter('authority', authorityFilter);

    authorityFilter.$inject = [];

    function authorityFilter() {
        return authorityFilter;

        function authorityFilter(authority) {
            if (_.isNil(authority)) {
                return authority;
            }

            if(authority==='ROLE_USER'){
                return "Benutzer";
            }
            if(authority==='ROLE_AGENT'){
                return "Verwalter";
            }
            if(authority==='ROLE_ADMIN'){
                return "Administrator";
            }
            if(authority==='ROLE_SYSTEM'){
                return "System";
            }
            if(authority==='ROLE_ISSUER'){
                return "Ausgabestelle";
            }
            if(authority==='ROLE_REVISOR'){
                return "Prüfer";
            }
            if(authority==='ROLE_MEMBER'){
                return "Mitglied";
            }

            return authority;

        }
    }
})();
