(function () {
    'use strict';

    angular
        .module('voucherApp')
        .controller('SaleVouchersDialogController', SaleVouchersDialogController);


    SaleVouchersDialogController.$inject = ['$scope', 'Dialogs', 'toastr', 'VoucherUtils', 'Vouchers', 'systemInfoLoader', 'issuer', 'Loading', '$state', 'GraphQL', '$q', 'DealOuts', 'Principal', 'Sales', 'Issuers','TabUtil'];

    function SaleVouchersDialogController($scope, Dialogs, toastr, VoucherUtils, Vouchers, systemInfoLoader, issuer, Loading, $state, GraphQL, $q, DealOuts, Principal, Sales, Issuers, TabUtil) {
        var vm = this;
        vm.worths = systemInfoLoader.worths;
        vm.issuer = issuer;
        vm.sales = [];

        vm.tabs = TabUtil.watch($scope, "dialog-tab");

        $scope.$watch("vm.activeTab", function (newVal) {
            $scope.$emit('bb.tab-changed', {});
        });
        updateSummery();

        vm.datePickerOptions = {maxDate: new Date()};


        Principal.identity().then(function (identity) {
            if (!identity.roleAdmin && !identity.roleAgent) {
                if (identity.issuerId !== vm.issuer.id) {
                    $state.go('accessdenied');
                }
            }
        });


        vm.single = {
            date: new Date()
        };
        vm.multi = {
            date: new Date()
        };

        vm.validateSingleSerial = function () {
            vm.single.valid = false;
            if (!_.isNil(vm.single.voucher) && !_.isNil(vm.single.voucher.serialString)) {
                vm.single.valid = true;
            }
        };


        vm.searchVouchers = function (filter) {

            var worth = $scope.worth;
            var state = $scope.state;
            var request = {
                name: "issuer",
                variables: {
                    id: vm.issuer.id
                },
                values: [{
                    name: "vouchersSearch",
                    variables: {states: ["InIssue", "InSubmission", "InAudit"], filter: filter, limit: 20},
                    values: ["id", "number", "worth", "serialString", "worthValue", "worthLabel"]
                }]
            };
            var deferred = $q.defer();
            GraphQL.then(request, function (data) {
                deferred.resolve(data.issuer.vouchersSearch);
            });
            return deferred.promise;

        };


        vm.onSerialEnter = function () {
            vm.validateSingleSerial();
            // vm.validate(serial, function () {
            vm.focusSerial = false;
            vm.focusDate = true;
            // });
        };

        vm.onDateEnter = function () {
            vm.checkSingle();
        };

        vm.checkSingle = function () {
            validateSingle().then(function () {
                vm.focusSerial = true;
                vm.focusDate = false;
                vm.dateOpen = false;
            })
        }

        function validateSingle() {
            var deferred = $q.defer();
            if (!_.isNil(vm.single) && !_.isNil(vm.single.voucher) && !_.isNil(vm.single.voucher.serialString)) {

                if (vm.single.date > new Date()) {
                    toastr.error("Datum nicht möglich");
                    deferred.reject();
                } else {

                    if (!_.some(vm.sales, function (s) {
                        return s.voucher.serialString === vm.single.voucher.serialString;
                    })) {
                        vm.sales.unshift(angular.copy(vm.single));
                        updateSummery();
                        toastr.success("Nummer hinzugefügt");
                    } else {
                        toastr.info("Nummer existierte bereits");
                    }
                    vm.single = {
                        date: new Date()
                    };
                    deferred.resolve();
                }
            } else {
                toastr.error("Nummer nicht hinzugefügt, weil nicht valide!");
                deferred.reject();
            }
            return deferred.promise;
        }

        vm.removeSale = function (index) {
            _.pullAt(vm.sales, index);
            updateSummery();
        };


        function updateSummery() {
            vm.summary = [];
            vm.worths.forEach(function (worth) {
                vm.summary.push({worth: worth.worth, min: null, max: null, minSerial: null, maxSerial: null, count: 0, value: 0, worthValue: worth.value});
            });
            vm.sales.forEach(function (sale) {
                var v = sale.voucher;
                vm.summary.forEach(function (sum) {
                    if (sum.worth === v.worth) {
                        sum.count++;
                        sum.value = sum.count * sum.worthValue;
                        if (sum.min === null || sum.min > v.number) {
                            sum.min = v.number;
                            sum.minSerial = v.serialString;
                        }
                        if (sum.max === null || sum.max < v.number) {
                            sum.max = v.number;
                            sum.maxSerial = v.serialString;
                        }
                    }
                })
            })
        }


        vm.searchVouchersStart = function (filter) {
            if (!_.isNil(vm.multi.end) && !_.isNil(vm.multi.end.worth)) {
                return vm.searchVouchersWithWorth(filter, vm.multi.end.worth);
            } else {
                return vm.searchVouchers(filter);
            }
        };

        vm.searchVouchersEnd = function (filter) {
            if (!_.isNil(vm.multi.start) && !_.isNil(vm.multi.start.worth)) {
                return vm.searchVouchersWithWorth(filter, vm.multi.start.worth);
            } else {
                return vm.searchVouchers(filter);
            }
        };


        vm.searchVouchersWithWorth = function (filter, worth) {
            var request = {
                name: "issuer",
                variables: {
                    id: vm.issuer.id
                },
                values: [{
                    name: "vouchersSearch",
                    variables: {state: "InIssue", worth: worth, filter: filter, limit: 20},
                    values: ["id", "number", "worth", "serialString", "worthValue", "worthLabel"]
                }]
            };
            var deferred = $q.defer();
            GraphQL.then(request, function (data) {
                deferred.resolve(data.issuer.vouchersSearch);
            });
            return deferred.promise;

        };


        vm.validateMulti = function () {
            vm.multi.valid = false;
            vm.multi.invalidRange = false;
            vm.multi.validStart = false;
            vm.multi.validEnd = false;
            if (!_.isNil(vm.multi.start) && !_.isNil(vm.multi.start.number)) {
                vm.multi.validStart = true;
                if (!_.isNil(vm.multi.end) && !_.isNil(vm.multi.end.number)) {
                    vm.multi.validEnd = true;
                    if (vm.multi.start.number > vm.multi.end.number) {
                        vm.multi.invalidRange = true;
                    } else {
                        vm.multi.valid = true;
                    }
                }
            }
        };


        vm.checkMulti = function () {
            vm.multiResultValid = false;
            var request = {
                worth: vm.multi.start.worth,
                from: vm.multi.start.number,
                to: vm.multi.end.number,
                state: "InIssue",
                issuerId: vm.issuer.id
            };
            Vouchers.countByState(request, function (result) {
                vm.multiResult = {
                    worth: vm.multi.start.worth,
                    count: result.data,
                    value: result.data * vm.multi.start.worthValue,
                    minSerial: vm.multi.start.serialString,
                    maxSerial: vm.multi.end.serialString
                }
                if (result.data > 0) {
                    vm.multiResultValid = true;
                }
            })
        };


        vm.submitMulti = function () {
            Dialogs.ask("Jetzt " + systemInfoLoader.plural + " als Reihe aktivieren?").then(function () {
                Loading.open(systemInfoLoader.plural + " werden aktiviert...");

                var sale = {
                    issuer: vm.issuer,
                    date: vm.multi.date,
                    worth: vm.multiResult.worth,
                    numberMin: vm.multi.start.number,
                    numberMax: vm.multi.end.number
                };

                Sales.createRange(sale, function () {
                    toastr.success(systemInfoLoader.plural + " wurden aktiviert!");
                    Loading.close();
                    // $state.go("stock-dealout");
                    vm.single = {
                        date: new Date()
                    };
                    vm.multi = {
                        date: new Date()
                    };
                    vm.multiResult = {};
                    vm.multiResultValid = false;

                }, function (result) {
                    toastr.error(systemInfoLoader.plural + " konnten nicht aktiviert werden!", result.data.message);
                    Loading.close();
                })
            });
        }


        vm.submit = function () {

            Dialogs.ask("Jetzt " + systemInfoLoader.plural + " aktivieren?").then(function () {
                Loading.open(systemInfoLoader.plural + " werden aktiviert...");

                var sale = {
                    issuer: vm.issuer,
                    vouchers: []
                };

                vm.sales.forEach(function (s) {
                    sale.vouchers.push({
                        voucher: s.voucher,
                        date: s.date
                    });
                });

                Sales.createList(sale, function () {
                    toastr.success(systemInfoLoader.plural + " wurden aktiviert!");
                    Loading.close();
                    // $state.go("stock-dealout");
                    vm.single = {
                        date: new Date()
                    };
                    vm.multi = {
                        date: new Date()
                    };
                    vm.sales = [];

                }, function (result) {
                    toastr.error(systemInfoLoader.plural + " konnten nicht aktiviert werden!", result.data.message);
                    Loading.close();
                })
            })
        }


        function loadStats() {

            var request = {
                name: "issuer",
                variables: {id: vm.issuer.id},
                values: [
                    "name",
                    {name: "statsIn", values: ["...stats"]},
                    {name: "statsStock", values: ["...stats"]},
                    {name: "statsOut", values: ["...stats"]}
                ],
                fragments: [
                    {name: "stats", on: "VoucherStat", values: ["worth", "value", "count", "minSerial", "maxSerial"]}
                ]
            };

            GraphQL.then(request, function (data) {
                vm.stats = data.issuer;
            })

        }

        loadStats();


        vm.lastSales = {
            page: 1,
            size: 10,
            total: 0,
            predicate: 'saleDate',
            reverse: false,
            load: loadSales,
            content: []

        };

        function loadSales() {

            var req = {
                id: vm.issuer.id,
                size: vm.lastSales.size,
                page: vm.lastSales.page - 1,
                sort: sortSales()
            };

            Issuers.getSales(req, function (result, headers) {
                vm.lastSales.total = headers('X-Total-Count');
                vm.lastSales.content = result;
                if (vm.lastSales.content !== null) {

                    vm.lastSales.content.forEach(function (sale) {
                        sale.statsLoading = true;
                        var request = {
                            name: "sale",
                            variables: {id: sale.id},
                            values: ["id", {
                                name: "stats",
                                values: [
                                    "worth",
                                    "value",
                                    "max",
                                    "min",
                                    "count"
                                ]
                            }]
                        };
                        GraphQL.then(request, function (result, sale) {
                            sale.stats = result.sale.stats;
                            sale.statTotal = 0;
                            sale.statCount = 0;
                            sale.stats.forEach(function (stat) {
                                sale.statTotal += stat.value;
                                sale.statCount += stat.count;
                            })
                            sale.statsLoading = false;
                        }, sale);
                    })
                }
            });

        }


        function sortSales() {
            var result = [vm.lastSales.predicate + ',' + (vm.lastSales.reverse ? 'asc' : 'desc')];
            if (vm.lastSales.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }

        // vm.updateValues();


        vm.removeOldsale = function (sale, index) {
            Dialogs.ask("Wirklich diesen Verkauf löschen?").then(function () {
                Issuers.deleteSale({id: vm.issuer.id, saleId: sale.id}, function () {
                    toastr.success("Verkauf gelöscht");
                    loadSales();
                }, function () {
                    toastr.error("Dieser Verkauf kann nicht mehr gelöscht werden!");
                })
            })
        };


        vm.salesStats = {
            range: {
                startDate: moment(),
                endDate: moment()
            }
        };

        $scope.$watch('vm.salesStats.range', function (range) {
            if (!_.isNil(range)) {

                var from = range.startDate.format('YYYY-MM-DD');
                var to = range.endDate.format('YYYY-MM-DD');
                if (from === "Invalid date" || to === "Invalid date") {
                    toastr.error("Datum ist nicht valide!");
                    return;
                }

                var request = {
                    name: "issuer",
                    variables: {
                        id: vm.issuer.id
                    },
                    values: [{
                        name: "saleStats",
                        variables: {
                            from: from,
                            to: to
                        },
                        values: ["worth", "count", "value", "minSerial", "maxSerial"]
                    }]
                }
                vm.salesStats.export = {
                    issuer: vm.issuer.id,
                    from: from,
                    to: to,
                    type: "sales"
                };
                GraphQL.then(request, function (result) {
                    vm.salesStats.stats = result.issuer.saleStats;

                });

            }
        }, true);

        loadSales();
        vm.loadSales = loadSales;
    }
})();
