'use strict';

angular.module('voucherApp')
    .directive('typeName', function (systemInfo) {
        return {
            scope: {
                typeName: "=?"
            },
            replace: true,
            restrict: 'EA',
            template: '<span>{{name}}</span>',
            link: function (scope, element, attrs) {
                var count = 2;
                if (scope.typeName) {
                    count = scope.typeName;
                }
                scope.name = systemInfo.name(count);
            }
        };
    });

