(function() {
    'use strict';

    angular
        .module('voucherApp')
        .factory('Account', Account);

    Account.$inject = ['$resource'];

    function Account ($resource) {
        var service = $resource('api/account', {}, {
            'get': { method: 'GET', params: {}, isArray: false,
                interceptor: {
                    response: function(response) {
                        // expose response
                        return response;
                    }
                }
            },
            'needsAGBs': {
                method: 'GET',
                url: 'api/account/needs-agb',
                isArray: false
            },
            'needsAccount': {
                method: 'GET',
                url: 'api/account/needs-account',
                isArray: false
            },
            'acceptAGBs': {
                method: 'GET',
                url: 'api/account/accept-agb',
                isArray: false
            }
        });

        return service;
    }
})();
