(function () {
    'use strict';

    angular
        .module('voucherApp')
        .controller('IssuersDialogController', IssuersDialogController);

    IssuersDialogController.$inject = ['toastr', '$state', 'Issuers', 'issuer', 'GraphQL'];

    function IssuersDialogController(toastr, $state, Issuers, issuer, GraphQL) {
        var vm = this;
        vm.cancel = cancel;
        vm.save = save;
        vm.issuer = issuer;

        function cancel() {
            $state.go("settings.issuers");
        }

        function onSaveSuccess(result) {
            vm.isSaving = false;
            toastr.success("Ausgabestelle wurde gespeichert!");
            $state.go("settings.issuers");
        }

        function onSaveError() {
            vm.isSaving = false;
            toastr.error("Ausgabestelle konnte nicht gespeichert werden!");
        }

        function save() {
            vm.isSaving = true;
            if (!_.isNil(vm.issuer.id)) {
                Issuers.update(vm.issuer, onSaveSuccess, onSaveError);
            } else {
                Issuers.save(vm.issuer, onSaveSuccess, onSaveError);
            }
        }
    }
})();
