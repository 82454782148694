(function () {
    'use strict';


    angular
        .module('voucherApp')
        .controller('DialogMultiChooseModalController', DialogMultiChooseModalController);

    DialogMultiChooseModalController.$inject = ['$scope', '$uibModalInstance', 'list', 'title', "prop", "text"];

    function DialogMultiChooseModalController($scope, $uibModalInstance, list, title, prop, text) {

        $scope.list = list;
        for (var i = 0; i < $scope.list.length; i++) {
            if (_.isNil($scope.list[i]._choosen)) {
                $scope.list[i]._choosen = false;
            }
        }
        $scope.prop = prop;
        $scope.title = title;
        $scope.text = text;


        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.valid = function () {
            for (var i = 0; i < $scope.list.length; i++) {
                if ($scope.list[i]._choosen) {
                    return true;
                }
            }
            return false;
        };

        $scope.choose = function (item, index) {
            if (_.isNil(item._choosen) || item._choosen === false) {
                item._choosen = true;
            } else {
                item._choosen = false;
            }
        }

        $scope.ok = function () {
            var result = [];
            for (var i = 0; i < $scope.list.length; i++) {
                if ($scope.list[i]._choosen) {
                    result.push($scope.list[i]);
                }
            }
            $uibModalInstance.close(result);
        }


    }
})();
