(function () {
    'use strict';


    angular
        .module('voucherApp')
        .controller('DialogModalController', DialogModalController);

    DialogModalController.$inject = ['$scope', '$uibModalInstance', 'options', 'type'];

    function DialogModalController($scope, $uibModalInstance, options, type) {

        $scope.type = type;
        $scope.options = options;
        $scope.inputnumber = null;
        $scope.inputtext = null;
        $scope.isValid = true;


        if(options.bindEnter===true){
            angular.element(document.body).one('keydown', function(event){
                if(event.keyCode===13){
                    $scope.ok();
                    event.preventDefault();
                }
            })
        }

        $scope.isValid = function () {
            if (type === "text") {
                return $scope.inputtext !== "" && $scope.inputtext !== null;
            }
            if (type === "number") {
                return $scope.inputnumber !== "" && $scope.inputnumber !== null;
            }
            return true;
        };

        $scope.ok = function () {
            if ($scope.type === 'number') {
                $uibModalInstance.close($scope.inputnumber);
            } else {
                if ($scope.type === 'text') {
                    $uibModalInstance.close($scope.inputtext);
                } else {
                    $uibModalInstance.close(true);
                }

            }
        };

        $scope.no = function () {
            if ($scope.type === 'number') {
                $uibModalInstance.close($scope.inputnumber);
            } else {
                if ($scope.type === 'text') {
                    $uibModalInstance.close($scope.inputtext);
                } else {
                    $uibModalInstance.close(false);
                }

            }
        };

        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };


    }
})();
