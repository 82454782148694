(function() {
    'use strict';

    angular
        .module('voucherApp')
        .controller('RevisorsDetailController', RevisorsDetailController);

    RevisorsDetailController.$inject = ['$stateParams', 'revisor'];

    function RevisorsDetailController($stateParams, revisor) {
        var vm = this;

        vm.revisor = revisor;

    }
})();
