(function () {
    'use strict';

    angular
        .module('voucherApp')
        .factory('stateHandler', stateHandler);

    stateHandler.$inject = ['$rootScope', '$state', '$sessionStorage', '$window', 'Auth', 'Principal', 'VERSION', 'systemInfo'];

    function stateHandler($rootScope, $state, $sessionStorage, $window, Auth, Principal, VERSION, systemInfo) {
        return {
            initialize: initialize
        };

        function initialize() {
            $rootScope.VERSION = VERSION;

            var stateChangeStart = $rootScope.$on('$stateChangeStart', function (event, toState, toStateParams, fromState) {
                $rootScope.toState = toState;
                $rootScope.toStateParams = toStateParams;
                $rootScope.fromState = fromState;

                // Redirect to a state with an external URL (http://stackoverflow.com/a/30221248/1098564)
                if (toState.external) {
                    event.preventDefault();
                    $window.open(toState.url, '_self');
                }

                if (Principal.isIdentityResolved()) {
                    Auth.authorize();
                }

            });

            var stateChangeSuccess = $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
                var title = systemInfo.singular() + ' WebApp';

                // Set the page title key to the one configured in state or use default one
                if (toState.ncyBreadcrumb && toState.ncyBreadcrumb.label) {
                    var newTitle = toState.ncyBreadcrumb.label;
                    if (newTitle.indexOf("{{") < 0) {
                        title = newTitle +" - "+title;
                    }
                } else {
                    if (toState.data && toState.data.pageTitle) {
                        title = toState.data.pageTitle +" - "+title;
                    }
                }
                $window.document.title = title;
                $window.scrollTo(0, 0);

            });

            $rootScope.$on('$destroy', function () {
                if (angular.isDefined(stateChangeStart) && stateChangeStart !== null) {
                    stateChangeStart();
                }
                if (angular.isDefined(stateChangeSuccess) && stateChangeSuccess !== null) {
                    stateChangeSuccess();
                }
            });
        }
    }
})();
