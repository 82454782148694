(function () {
    'use strict';

    angular
        .module('voucherApp')
        .controller('ContentController', ContentController);


    ContentController.$inject = ['$scope', '$state'];

    function ContentController($scope, $state) {
        $scope.$state = $state;
    }
})();
