'use strict';

angular.module('voucherApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('statistics', {
                parent: 'app',
                url: '/statistics?tab',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SYSTEM', 'ROLE_AGENT', 'ROLE_REVISOR']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/views/statistics/statistics.html',
                        controller: 'StatisticsController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: 'Statistiken'
                },
                params: {
                    tab: {
                        value: "0"
                    }
                },
                reloadOnSearch: false
            })
    });
