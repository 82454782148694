'use strict';

angular.module('voucherApp')
    .factory('Submissions', function ($resource) {
        return $resource('api/submissions/:id', {}, {

            'getSales': {
                url: 'api/submissions',
                method: 'GET',
                isArray: true
            },
            'createSubmission': {
                method: 'POST',
                isArray: false,
                url: 'api/submissions'
            },
            'deleteSubmission': {
                method: 'DELETE',
                isArray: false,
                url: 'api/submissions/:id'
            }

        });
    });
