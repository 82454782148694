(function () {
    'use strict';

    angular
        .module('voucherApp')
        .controller('SubmitVouchersDialogController', SubmitVouchersDialogController);


    SubmitVouchersDialogController.$inject = ['$scope', 'Dialogs', 'toastr', 'VoucherUtils', 'Vouchers', 'systemInfoLoader', 'member', 'Loading', '$state', 'GraphQL', '$q', 'Submissions', 'Principal', 'TabUtil', 'Issuers'];

    function SubmitVouchersDialogController($scope, Dialogs, toastr, VoucherUtils, Vouchers, systemInfoLoader, member, Loading, $state, GraphQL, $q, Submissions, Principal, TabUtil, Issuers) {
        var vm = this;
        vm.worths = systemInfoLoader.worths;
        vm.member = member;
        vm.submissions = [];

        vm.tabs = TabUtil.watch($scope, "dialog-tab");

        $scope.$watch("vm.tabs.active", function (newVal) {
            $scope.$emit('bb.tab-changed', {});
        });
        updateSummery();

        vm.datePickerOptions = {maxDate: new Date()};


        Principal.identity().then(function (identity) {
            if (!identity.roleAdmin && !identity.roleAgent) {
                if (identity.memberId !== vm.member.id) {
                    $state.go('accessdenied');
                }
            }
        });


        vm.submission = {
            date: new Date()
        };

        vm.searchVouchers = function (filter) {

            var worth = $scope.worth;
            var state = $scope.state;
            var request = {
                name: "vouchersSearch",
                variables: {states: ["Outside", "BookedOut", "InIssue"], filter: filter, limit: 20},
                values: ["id", "number", "worth", "serialString", "worthValue", "worthLabel", "valid", "validStart", "validEnd", "state"]

            };
            var deferred = $q.defer();
            GraphQL.then(request, function (data) {
                deferred.resolve(data.vouchersSearch);
            });
            return deferred.promise;

        };


        vm.validateSubmission = function () {
            vm.submission.valid = false;
            if(vm.submission.date == null) {
                vm.submission.date = new Date();
            }
            console.log(vm.submission)
            if (!_.isNil(vm.submission.voucher) && !_.isNil(vm.submission.voucher.serialString)) {
                var voucher = vm.submission.voucher;
                vm.submission.valid = true;
                if (voucher.state === 'InIssue') {
                    vm.submission.result = "MaybeOk";
                }
                if (voucher.state === 'Outside') {
                    var end = moment(voucher.validEnd);
                    var until = moment(vm.submission.date);
                    if (end.isSameOrAfter(until)) {
                        vm.submission.result = "Ok";
                    } else {
                        vm.submission.result = "Expired";

                        if(until.isSameOrBefore(end.add(3, "M"))){
                            vm.submission.result = "ExpiredButOk";
                        }
                    }
                }
            }
        };


        vm.onSerialEnter = function () {
            vm.validateSubmission();
            vm.focusSerial = false;
            vm.focusDate = true;
        };

        vm.onDateEnter = function () {
            vm.checkSubmission();
        };

        vm.checkSubmission = function () {
            validateVoucher().then(function () {
                vm.focusSerial = true;
                vm.focusDate = false;
                vm.dateOpen = false;
            });
        };

        function validateVoucher() {
            var deferred = $q.defer();
            if (!_.isNil(vm.submission) && !_.isNil(vm.submission.voucher) && !_.isNil(vm.submission.voucher.serialString)) {

                if (vm.submission.date > new Date()) {
                    toastr.error("Datum nicht möglich");
                    deferred.reject();
                } else {

                    if (!_.some(vm.submissions, function (s) {
                        return s.voucher.serialString === vm.submission.voucher.serialString;
                    })) {
                        // re-evaluate, because of the date!
                        vm.validateSubmission();
                        vm.submissions.unshift(angular.copy(vm.submission));
                        updateSummery();
                        toastr.success("Nummer hinzugefügt");


                        if(vm.submission.result === "MaybeOk"){
                            toastr.warning("Ausgabestelle hat "+vm.submission.voucher.serialString+" noch nicht aktiviert!", "Bitte gesondert prüfen", {
                                closeButton: true,
                                extendedTimeOut: 0,
                                timeOut: 0
                            });
                        }

                    } else {
                        toastr.info("Nummer existierte bereits");
                    }
                    vm.submission = {
                        date: new Date()
                    };
                    deferred.resolve();
                }
            } else {
                toastr.error("Nummer nicht hinzugefügt, weil nicht valide!");
                deferred.reject();
            }
            return deferred.promise;
        }

        vm.removeSubmission = function (index) {
            _.pullAt(vm.submissions, index);
            updateSummery();
        };


        function updateSummery() {
            vm.summary = [];
            vm.worths.forEach(function (worth) {
                vm.summary.push({worth: worth.worth, min: null, max: null, minSerial: null, maxSerial: null, count: 0, value: 0, worthValue: worth.value});
            });
            vm.submissions.forEach(function (sale) {
                var v = sale.voucher;
                vm.summary.forEach(function (sum) {
                    if (sum.worth === v.worth) {
                        sum.count++;
                        sum.value = sum.count * sum.worthValue;
                        if (sum.min === null || sum.min > v.number) {
                            sum.min = v.number;
                            sum.minSerial = v.serialString;
                        }
                        if (sum.max === null || sum.max < v.number) {
                            sum.max = v.number;
                            sum.maxSerial = v.serialString;
                        }
                    }
                })
            })
        }


        vm.submit = function () {

            Dialogs.ask("Jetzt " + systemInfoLoader.plural + " einreichen?").then(function () {
                Loading.open(systemInfoLoader.plural + " werden eingereicht...");

                var request = {
                    member: vm.member,
                    submissions: []
                };

                vm.submissions.forEach(function (s) {
                    request.submissions.push({
                        voucher: s.voucher,
                        redemptiondate: s.date
                    });
                });

                Submissions.createSubmission(request, function () {
                    toastr.success(systemInfoLoader.plural + " wurden eingereicht!");
                    Loading.close();
                    vm.submission = {
                        date: new Date()
                    };
                    vm.submissions = [];
                    Dialogs.yesNo("Einreichung erfolgreich! Am Ende des Monats wird automatisch eine Abrechnungsbogen mit allen Einreichungen erzeugt.", "Jetzt alle Einreichungen anzeigen?").then(function(){
                        vm.tabs.active = 1;
                    })

                }, function (result) {
                    toastr.error(systemInfoLoader.plural + " konnten nicht eingereicht werden!", result.data.message);
                    Loading.close();
                })
            })
        }


        vm.lastSubmissions = {
            page: 1,
            size: 20,
            total: 0,
            predicate: 'submissiondate',
            reverse: false,
            load: loadSubmissions,
            content: []

        };

        function loadSubmissions() {

            var request = {
                name: "member",
                variables: {id: vm.member.id},
                values: ["id",
                    {
                        name: "submissions",
                        variables: {
                            page: vm.lastSubmissions.page - 1,
                            size: vm.lastSubmissions.size,
                            sort: sortSubmissions()
                        },
                        values: ["id", "submissiondate", "redemptiondate", "state",
                            {
                                name: "voucher", values: ["id", "state", "serialString", "worthValue", "worthLabel"]
                            },
                            {
                                name: "docket", values: ["id", "date"]
                            }
                        ]

                    }]
            };

            GraphQL.then(request, function (result, ctx, headers) {
                vm.lastSubmissions.total = headers('X-Total-Count');
                vm.lastSubmissions.content = result.member.submissions;
            })
        }


        function sortSubmissions() {
            var result = [vm.lastSubmissions.predicate + ',' + (vm.lastSubmissions.reverse ? 'asc' : 'desc')];
            if (vm.lastSubmissions.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }


        vm.removeSubmittedSubmission = function (submission, index) {
            Dialogs.ask("Wirklich diese Einreichung löschen?").then(function () {
                Submissions.deleteSubmission({id: submission.id}, function () {
                    toastr.success("Einreichung gelöscht");
                    vm.loadSubmissions();
                }, function () {
                    toastr.error("Diese Einreichung kann nicht mehr gelöscht werden!");
                })
            })
        };


        vm.submissionStats = {
            range: {
                startDate: moment(),
                endDate: moment()
            }
        };

        $scope.$watch('vm.submissionStats.range', loadSubmissionStats, true);

        function loadSubmissionStats(range) {
            if (!_.isNil(range)) {

                var from = range.startDate.format('YYYY-MM-DD');
                var to = range.endDate.format('YYYY-MM-DD');
                if (from === "Invalid date" || to === "Invalid date") {
                    toastr.error("Datum ist nicht valide!");
                    return;
                }

                var request = {
                    name: "member",
                    variables: {
                        id: vm.member.id
                    },
                    values: [{
                        name: "submissionStats",
                        variables: {
                            from: from,
                            to: to
                        },
                        values: ["worth", "count", "value", "minSerial", "maxSerial"]
                    }]
                }

                vm.submissionStats.export = {
                    from: from,
                    to: to,
                    member: vm.member.id,
                    type: "submissions"
                };

                GraphQL.then(request, function (result) {
                    vm.submissionStats.stats = result.member.submissionStats;
                });

            }
        }

        loadSubmissions();
        vm.loadSubmissions = function () {
            loadSubmissions();
            loadSubmissionStats(vm.submissionStats.range);
        };
    }
})();
