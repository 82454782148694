'use strict';

angular.module('voucherApp')
    .factory('DealOuts', function ($resource) {
        return $resource('api/deal-outs/:id', {}, {

            'create': {
                method: 'POST',
                url: 'api/deal-outs'
            },
            'getDealOuts': {
                method: 'GET',
                isArray: true,
                url: 'api/deal-outs'
            },
            'clearDealOutsIssuer': {
                method: 'GET',
                isArray: false,
                url: 'api/deal-outs/clear-all/:issuerId'
            }
        });
    });
