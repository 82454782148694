(function () {
    'use strict';

    angular
        .module('voucherApp')
        .factory('User', User);

    User.$inject = ['$resource'];

    function User($resource) {
        var service = $resource('api/users/:id', {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'save': {method: 'POST'},
            'update': {method: 'PUT'},
            'delete': {method: 'DELETE'},
            'sendWelcomeMail': {
                method: 'POST',
                url: 'api/users/:id/send-welcome-mail',
                isArray: false
            },
            'resendWelcomeMail': {
                method: 'GET',
                url: 'api/users/:id/resend-welcome-mail',
                isArray: false
            }
        });

        return service;
    }
})();
