(function () {
    'use strict';

    angular
        .module('voucherApp')
        .controller('SaleVouchersController', SaleVouchersController);


    SaleVouchersController.$inject = ['$scope', 'Dialogs', 'toastr', 'VoucherUtils', 'Vouchers', 'systemInfoLoader', 'GraphQL', 'TabUtil', 'Principal'];

    function SaleVouchersController($scope, Dialogs, toastr, VoucherUtils, Vouchers, systemInfoLoader, GraphQL, TabUtil, Principal) {
        var vm = this;


        vm.stock = [];
        vm.lastDealOuts = [];
        vm.tabs = TabUtil.watch($scope, "tab");


        $scope.$watch("vm.activeTab", function (newVal) {
            $scope.$emit('bb.tab-changed', {});
        });

        vm.predicate = "id";
        vm.reverse = false;

        vm.list = {
            page: 1,
            size: 20,
            total: 0,
            items: []
        };


        function loadStock() {
            var request = {
                name: "statistics",
                values: [{
                    name: "stockInIssue", values: [
                        "worth",
                        "count",
                        "value",
                        "min",
                        "minSerial",
                        "max",
                        "maxSerial"]
                }]
            };

            GraphQL.query(request).then(function (response) {
                vm.stock = response.data.statistics.stockInIssue;
            });
        }

        function loadIssuers() {
            var request = {
                name: "issuers",
                values: ["id", "name"]
            };
            GraphQL.then(request, function (data) {
                vm.issuers = data.issuers;
            });
        }

        function loadLastSales() {


            var request = {
                name: "sales",
                variables: {
                    page: 0,
                    size: 10,
                    sort: ["id,desc"]
                },
                values: ["id", "saleDate", "name",
                    {name: "issuer", values: ["id", "name"]}
                ]
            };
            GraphQL.then(request, function (data) {
                vm.lastSales = data.sales;
            });

        }


        vm.loadList = function () {

            var request = {
                name: "sales",
                variables: {
                    page: vm.list.page - 1,
                    size: 20,
                    sort: sort()
                },
                values: ["id", "saleDate", "name",
                    {name: "issuer", values: ["id", "name"]}
                ]
            };
            GraphQL.then(request, function (data, ctx, headers) {
                vm.list.items = data.sales;
                vm.list.total = headers('X-Total-Count');
            });


            // Sales.getSales({page: vm.list.page - 1, size: 10, sort: sort()}, function (result, headers) {
            //     vm.list.total = headers('X-Total-Count');
            //     vm.list.items = result;
            // })
        };


        function sort() {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }


        loadIssuers();
        loadStock();
        loadLastSales();
        vm.loadList();


    }
})();
