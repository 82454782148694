(function () {
    'use strict';

    angular
        .module('voucherApp')
        .controller('StockVouchersController', StockVouchersController);


    StockVouchersController.$inject = ['$scope', 'Dialogs', 'toastr', 'VoucherUtils', 'Vouchers', 'systemInfoLoader', 'AuthServerProvider', 'GraphQL'];

    function StockVouchersController($scope, Dialogs, toastr, VoucherUtils, Vouchers, systemInfoLoader, AuthServerProvider, GraphQL) {
        var vm = this;
        vm.accessKey = AuthServerProvider.getToken();
        vm.worths = systemInfoLoader.worths;

        vm.step = 0;

        vm.activeTab = 0;

        vm.predicate = "id";
        vm.reverse = false;

        vm.list = {
            page: 1,
            size: 20,
            total: 0,
            items: []
        };


        vm.chooseWorth = function (worth) {
            vm.step = 1;
            vm.worth = worth;
            vm.initLastGenerated();
        };

        vm.valueCount = 1000;
        vm.checkDigitStart = "-";
        vm.checkDigitEnd = "-";

        vm.valueCount = 1000;
        vm.valueStart = "000001";

        vm.checkDigitLast = "-";
        vm.lastGeneratedNumber = 0;

        vm.currentWorthDigit = vm.worthDigit;

        vm.initLastGenerated = function () {
            Vouchers.lastGenerated({worth: vm.worth.worth}, function (result) {
                vm.lastGeneratedNumber = result.number;
                vm.updateLastGenerated();
            }, function (error) {
                vm.lastGeneratedNumber = 0;
                vm.updateLastGenerated();
            });
        };

        vm.updateLastGenerated = function () {
            vm.lastGenerated = VoucherUtils.numberToString(vm.lastGeneratedNumber);
            Vouchers.checkDigit({number: vm.lastGeneratedNumber}, function (result) {
                vm.checkDigitLast = result.data;
            });
            if (parseInt(vm.valueStart) <= 0) {
                vm.valueStart = "000001";
            }
        };

        vm.updateValues = function () {
            vm.worthDigit = VoucherUtils.worthDigit(vm.worth);
            vm.currentWorthDigit = vm.worthDigit;
            vm.worthType = VoucherUtils.transformWorth(vm.worth);
            vm.initLastGenerated();
            if (vm.lastGeneratedNumber >= 0) {
                vm.valueEnd = VoucherUtils.numberToString(parseInt(vm.valueStart) + vm.valueCount - 1);
                Vouchers.checkDigit({number: vm.valueStart}, function (result) {
                    vm.checkDigitStart = result.data;
                });
                Vouchers.checkDigit({number: vm.valueEnd}, function (result) {
                    vm.checkDigitEnd = result.data;
                });
            }
        };

        // vm.updateValues();

        vm.stock = [];

        function loadStock() {
            var request = {
                name: "statistics",
                values: [{
                    name: "stockInDepot", values: [
                        "worth",
                        "count",
                        "value",
                        "min",
                        "minSerial",
                        "max",
                        "maxSerial"]
                }]
            };

            GraphQL.query(request).then(function(response){
              console.log(response.data);
              vm.stock = response.data.statistics.stockInDepot;
            });
        }

        loadStock();


        vm.loadList = function () {
            Vouchers.getByState({state: 'InDepot', page: vm.list.page - 1, size: 20, sort: sort()}, function (result, headers) {
                vm.list.total = headers('X-Total-Count');
                vm.list.items = result;
            });
        };


        function sort() {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }

        vm.loadList();


    }
})();
