(function() {
    'use strict';

    angular
        .module('voucherApp')
        .factory('TabUtil', TabUtil);

    TabUtil.$inject = ['$state', '$stateParams', '$location'];

    function TabUtil($state, $stateParams, $location) {

        var service = {
            watch: watch
        };

        return service;

        function watch(scope, param) {
            var vm = {};
            vm.active = 0;
            if (!_.isNil($stateParams[param])) {
                vm.active = parseInt($stateParams[param]);
            }

            scope.$watch(function () {
                return vm.active;
            }, function (newVal) {
                if(!_.isNil(newVal)) {
                    scope.$emit('bb.tab-changed', {});
                    $location.search(param, "" + newVal);
                }
            });
            return vm;
        }


    }

})();
