'use strict';

angular.module('voucherApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('logout', {
                parent: 'blank',
                url: '/logout',
                data: {
                    authorities: []
                },
                views: {
                    'content@blank': {
                        templateUrl: 'app/account/logout/logout.html',
                        controller: 'LogoutController'
                    }
                }
            });
    });
