'use strict';

angular.module('voucherApp')
    .directive('overlayLoading', function ($document, $timeout) {
        return {
            templateUrl: 'app/directives/overlayLoading/overlayLoading.html',
            restrict: 'EA',
            link: function (scope, element, attrs) {


                hide();
                init();


                function init() {
                    scope.task = "";
                    scope.title = "Einen Moment bitte...";
                    scope.showbar = false;
                    scope.ticks = {
                        total: 0,
                        tick: 0
                    };
                    scope.clicks = 0;
                }

                function show(title, task, showbar) {
                    init();
                    if (!_.isNil(showbar)) {
                        scope.showbar = (showbar === true);
                    }
                    if (!_.isNil(title) && title !== '') {
                        scope.title = title;
                    } else {
                        scope.title = "Einen Moment bitte...";
                    }
                    scope.task = task;
                    $(element).show();
                }

                function hide() {

                    $(element).hide();
                    init();
                }

                scope.$on("loading:open", function (event, data) {
                    show(data.title, data.task, data.showbar);
                });


                scope.$on("loading:task", function (event, data) {
                    if (!_.isNil(data.task)) {
                        scope.task = data.task;
                    }
                });

                scope.$on("loading:tick", function (event, data) {
                    if (!_.isNil(data.total)) {
                        scope.ticks.total = data.total;
                    }
                    if (!_.isNil(data.tick)) {
                        scope.ticks.tick = data.tick;
                    }
                    if (!_.isNil(data.task)) {
                        scope.task = data.task;
                    }
                });

                scope.$on("loading:close", function (event) {
                    hide();
                });

                $document.bind('keyup', function (e) {
                    if (e.keyCode === 27) {
                        hide();
                    }
                });

                scope.clicked = function () {
                    scope.clicks++;
                    if (scope.clicks >= 5) {
                        hide();
                    }
                }
            }
        };
    });
