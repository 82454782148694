(function () {
    'use strict';

    angular
        .module('voucherApp')
        .controller('StockGenerateController', StockGenerateController);


    StockGenerateController.$inject = ['$scope', 'Dialogs', 'toastr', 'VoucherUtils', 'Vouchers', 'systemInfoLoader', 'AuthServerProvider' ];

    function StockGenerateController($scope, Dialogs, toastr, VoucherUtils, Vouchers, systemInfoLoader, AuthServerProvider) {
        var vm = this;
        vm.accessKey = AuthServerProvider.getToken();
        vm.worths = systemInfoLoader.worths;

        vm.worth = vm.worths[0];
        vm.valueCount = 1000;
        vm.checkDigitStart = "-";
        vm.checkDigitEnd = "-";

        vm.valueCount = 1000;
        vm.valueStart = "000001";

        vm.checkDigitLast = "-";
        vm.lastGeneratedNumber = 0;

        vm.currentWorthDigit = vm.worthDigit;

        vm.initLastGenerated = function(){
            Vouchers.lastGenerated({worth: vm.worth.worth}, function (result) {
                vm.lastGeneratedNumber = result.number;
                vm.updateLastGenerated();
            }, function (error) {
                vm.lastGeneratedNumber = 0;
                vm.updateLastGenerated();
            });
        };

        vm.updateLastGenerated = function () {
            vm.lastGenerated = VoucherUtils.numberToString(vm.lastGeneratedNumber);
            Vouchers.checkDigit({number: vm.lastGeneratedNumber}, function (result) {
                vm.checkDigitLast = result.data;
            });
            if (parseInt(vm.valueStart) <= 0) {
                vm.valueStart = "000001";
            }
        };

        vm.updateValues = function () {
            vm.worthDigit = VoucherUtils.worthDigit(vm.worth);
            vm.currentWorthDigit = vm.worthDigit;
            vm.worthType = VoucherUtils.transformWorth(vm.worth);
            vm.initLastGenerated();
            if (vm.lastGeneratedNumber >= 0) {
                vm.valueEnd = VoucherUtils.numberToString(parseInt(vm.valueStart) + vm.valueCount - 1);
                Vouchers.checkDigit({number: vm.valueStart}, function (result) {
                    vm.checkDigitStart = result.data;
                });
                Vouchers.checkDigit({number: vm.valueEnd}, function (result) {
                    vm.checkDigitEnd = result.data;
                });
            }
        };
        vm.updateValues();



    }
})();
