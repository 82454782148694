(function () {
    'use strict';

    angular
        .module('voucherApp')
        .controller('StockDealOutController', StockDealOutController);


    StockDealOutController.$inject = ['$scope', 'Dialogs', 'toastr', 'VoucherUtils', 'Vouchers', 'systemInfoLoader', 'GraphQL', 'DealOuts'];

    function StockDealOutController($scope, Dialogs, toastr, VoucherUtils, Vouchers, systemInfoLoader, GraphQL, DealOuts) {
        var vm = this;

        vm.stock = [];
        vm.lastDealOuts = [];
        vm.activeTab = 0;

        vm.predicate = "id";
        vm.reverse = false;

        vm.list = {
            page: 1,
            size: 20,
            total: 0,
            items: []
        };


        function loadStock() {
            var request = {
                name: "statistics",
                values: [{
                    name: "stockInIssue", values: [
                        "worth",
                        "count",
                        "value",
                        "min",
                        "minSerial",
                        "max",
                        "maxSerial"]
                }]
            };

            GraphQL.query(request).then(function (response) {
                vm.stock = response.data.statistics.stockInIssue;
            });
        }

        function loadIssuers() {
            var request = {
                name: "issuers",
                values: ["id", "name"]
            };
            GraphQL.then(request, function (data) {
                vm.issuers = data.issuers;
            });
        }

        function loadLastDealOuts() {
            DealOuts.getDealOuts({page: 0, size: 10, sort:"id,desc"}, function (result) {
                vm.lastDealOuts = result;
            })
        }




        vm.loadList = function () {
            DealOuts.getDealOuts({page: vm.list.page - 1, size: 10, sort: sort()}, function (result, headers) {
                vm.list.total = headers('X-Total-Count');
                vm.list.items = result;
            })
        };


        function sort() {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }


        loadIssuers();
        loadStock();
        loadLastDealOuts();
        vm.loadList();


    }
})();
