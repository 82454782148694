(function () {
    'use strict';

    angular
        .module('voucherApp')
        .filter('worth', worthFilter);

    worthFilter.$inject = [];

    function worthFilter() {
        return worthFilter;

        function worthFilter(worth) {
            if (_.isNil(worth)) {
                return worth;
            }
            if (!_.isString(worth)) {
                return worth;
            }
            if (worth === 'Five') {
                return "5er";
            }
            if (worth === 'Ten') {
                return "10er";
            }
            if (worth === 'Fifteen') {
                return "15er";
            }
            if (worth === 'Twenty') {
                return "20er";
            }
            if (worth === 'TwentyFive') {
                return "25er";
            }
            if (worth === 'Thirty') {
                return "30er";
            }
            if (worth === 'ThirtyFive') {
                return "35er";
            }
            if (worth === 'Forty') {
                return "40er";
            }
            if (worth === 'FortyFour') {
                return "44er";
            }
            if (worth === 'Fifty') {
                return "50er";
            }
            return worth;

        }
    }
})();
