'use strict';

angular.module('voucherApp')
    .directive('goBack', function ($window, $state) {
        return {
            restrict: 'EA',
            controller: function ($scope, $element) {
                if ($state.includes("blank")) {
                    $($element).hide();
                }
                $element.on('click', function ($event) {
                    $event.stopPropagation();
                    if ($window.history.length) {
                        $window.history.back();
                    } else {
                        // $window.close();
                    }
                });
            }
        };
    });
