(function () {
    'use strict';


    angular
        .module('voucherApp')
        .provider('Dialogs', Dialogs);

    function Dialogs() {

        /*jshint validthis: true */
        this.$get = getService;

        getService.$inject = ['$uibModal', '$q'];

        function getService($uibModal, $q) {

            return {
                open: open,
                error: error,
                info: info,
                ask: ask,
                yesNo: yesNo,
                yesNoCancel: yesNoCancel,
                askNumber: askNumber,
                askText: askText,
                success: success,
                warning: warning,
                listChoose: listChoose,
                multiChoose: multiChoose
            };

            function open(options, type) {
                if (options.cancelButtonText) {
                    options.showCancelButton = true
                }
                if (options.noButtonText) {
                    options.showNoButton = true
                }
                if (!type) {
                    type = "none";
                }
                var deferred = $q.defer();
                var instance = $uibModal.open({
                    templateUrl: 'app/components/dialog/dialog.modal.html',
                    controller: 'DialogModalController',
                    size: "lg",
                    backdrop: 'static',
                    resolve: {
                        options: function () {
                            return options;
                        },
                        type: function () {
                            return type;
                        }
                    }
                });
                instance.result.then(function (result) {
                    deferred.resolve(result);
                }, function () {
                    deferred.reject();
                });
                return deferred.promise;
            }

            function error(options) {
                if (_.isString(options)) {
                    options = {
                        title: options
                    }
                }
                return this.open(options, "error");
            }

            function info(options) {
                if (_.isString(options)) {
                    options = {
                        title: options
                    }
                }
                return this.open(options, "info");
            }

            function ask(options, secondTitle, bindEnter) {
                if (_.isString(options)) {
                    options = {
                        title: options,
                        showCancelButton: true,
                        secondTitle: secondTitle
                    }
                } else {
                    if (options.cancelButtonText) {
                        options.showCancelButton = true
                    }
                    if(secondTitle){
                        options.secondTitle = secondTitle;
                    }
                }
                if(bindEnter===false) {
                    options.bindEnter = false;
                }else{
                    options.bindEnter = true;
                }
                return this.open(options, "question");
            }

            function yesNo(options, secondTitle) {
                if (_.isString(options)) {
                    options = {
                        title: options,
                        cancelButtonText: "Nein",
                        showCancelButton: true,
                        secondTitle: secondTitle
                    }
                } else {
                    options.cancelButtonText = "Nein";
                    options.showCancelButton = true
                    if(secondTitle){
                        options.secondTitle = secondTitle;
                    }

                }
                return this.open(options, "question");
            }

            function yesNoCancel(options) {
                if (_.isString(options)) {
                    options = {
                        title: options,
                        showNoButton: true,
                        noButtonText: "Nein",
                        cancelButtonText: "Abbrechen",
                        showCancelButton: true
                    }
                } else {
                    if(_.isNil(options.noButtonText)) {
                        options.noButtonText = "Nein";
                    }
                    options.showNoButton = true;
                    if(_.isNil(options.cancelButtonText)) {
                        options.cancelButtonText = "Abbrechen";
                    }
                    options.showCancelButton = true

                }
                return this.open(options, "question");
            }


            function askNumber(options) {
                if (_.isString(options)) {
                    options = {
                        title: options
                    }
                } else {
                    if (options.cancelButtonText) {
                        options.showCancelButton = true
                    }
                }
                return this.open(options, "number");
            }

            function askText(options, otherOptions) {
                if (_.isString(options)) {
                    options = {
                        title: options
                    }
                } else {
                    if (options.cancelButtonText) {
                        options.showCancelButton = true
                    }
                }
                if(!_.isNil(otherOptions)){
                    if(otherOptions.cancel === true){
                        options.showCancelButton = true
                    }
                }
                return this.open(options, "text");
            }

            function success(options) {
                if (_.isString(options)) {
                    options = {
                        title: options
                    }
                }
                return this.open(options, "success");
            }

            function warning(options) {
                if (_.isString(options)) {
                    options = {
                        title: options
                    }
                }
                return this.open(options, "warning");
            }


            function listChoose(list, labelProp, title){
                if(_.isNil(title)){
                    title = "Bitte wählen";
                }
                var deferred = $q.defer();
                var instance = $uibModal.open({
                    templateUrl: 'app/components/dialog/dialog.choose.modal.html',
                    controller: 'DialogChooseModalController',
                    size: "lg",
                    resolve: {
                        list: function () {
                            return list;
                        },
                        prop: function () {
                            return labelProp;
                        },
                        title: function(){
                            return title;
                        }
                    }
                });
                instance.result.then(function (result) {
                    deferred.resolve(result);
                }, function () {
                    deferred.reject();
                });
                return deferred.promise;
            }


            function multiChoose(list, labelProp, title, text){
                if(_.isNil(title)){
                    title = "Bitte wählen";
                }
                if(_.isNil(text)){
                    text = "Bitte einen oder mehrere Auswählen";
                }
                var deferred = $q.defer();
                var instance = $uibModal.open({
                    templateUrl: 'app/components/dialog/multi-choose/dialog.multi-choose.modal.html',
                    controller: 'DialogMultiChooseModalController',
                    size: "lg",
                    resolve: {
                        list: function () {
                            return list;
                        },
                        prop: function () {
                            return labelProp;
                        },
                        title: function(){
                            return title;
                        },
                        text: function(){
                            return text;
                        }
                    }
                });
                instance.result.then(function (result) {
                    deferred.resolve(result);
                }, function () {
                    deferred.reject();
                });
                return deferred.promise;
            }

        }
    }
})();
