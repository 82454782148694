(function () {
    'use strict';

    angular
        .module('voucherApp')
        .controller('VoucherController', VoucherController);


    VoucherController.$inject = ['$scope', 'Dialogs', 'toastr', 'voucherId', 'VoucherUtils', 'Vouchers', 'systemInfoLoader', 'AuthServerProvider', 'GraphQL'];

    function VoucherController($scope, Dialogs, toastr, voucherId, VoucherUtils, Vouchers, systemInfoLoader, AuthServerProvider, GraphQL) {
        var vm = this;
        vm.accessKey = AuthServerProvider.getToken();

        function loadVoucher() {
            var request = {
                name: "voucher",
                variables: {
                    id: voucherId
                },
                values: [
                    "number",
                    "checkdigit",
                    "serialNumber",
                    "serialString",
                    "worth",
                    "worthValue",
                    "worthLabel",
                    "valid",
                    "validStart",
                    "validEnd",
                    "state",
                    {
                        name: "dealOut", values: ["id", "dealOutAt", "dealOutBy", {name: "issuer", values: ["id", "name"]}]
                    },
                    {
                        name: "sale", values: ["id", "saleDate", "name", {name: "issuer", values: ["id", "name"]}]
                    },
                    {
                        name: "submission",
                        values: ["id", "submissiondate", "redemptiondate", {name: "member", values: ["id", "name"]}, {name: "docket", values: ["id", "state", "date", {name: "audit", values: ["id", "date", "approved"]}]}]
                    }
                ]
            };

            GraphQL.then(request, function (data) {
                vm.voucher = data.voucher;
            });
        }

        vm.destroyVoucher = function () {
            Dialogs.ask("Diesen " + systemInfoLoader.singular + " wirklich als \"vernichtet\" markieren?", "Der " + systemInfoLoader.singular + " darf nicht mehr in den Verkauf und muss auch physisch zerstört werden!").then(function () {
                Vouchers.destroyVoucher({id: voucherId}, function (response) {
                    if(response.result===true) {
                        toastr.success(systemInfoLoader.singular + " wurde vernichtet!");
                        loadVoucher();
                    }else{
                        toastr.error(systemInfoLoader.singular + " kann nicht mehr vernichtet werden!");
                    }
                }, function () {
                    toastr.error(systemInfoLoader.singular + " kann nicht vernichtet werden!");
                })
            })
        }

        loadVoucher();

    }
})();
