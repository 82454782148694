(function () {
    'use strict';

    angular
        .module('voucherApp')
        .controller('StockVouchersDialogController', StockVouchersDialogController);


    StockVouchersDialogController.$inject = ['$scope', 'Dialogs', 'toastr', 'VoucherUtils', 'Vouchers', 'systemInfoLoader', 'worthValue', 'Loading', '$state'];

    function StockVouchersDialogController($scope, Dialogs, toastr, VoucherUtils, Vouchers, systemInfoLoader, worthValue, Loading, $state) {
        var vm = this;
        vm.worths = systemInfoLoader.worths;
        vm.worths.forEach(function (w) {

            if (w.value === worthValue) {
                vm.worth = w;
            }
        });
        if (_.isNil(vm.worth)) {
            toastr.error("Wert existiert nicht!");
        }

        initLastGenerated();

        vm.valueCount = 1000;
        vm.checkDigitStart = "-";
        vm.checkDigitEnd = "-";

        vm.valueCount = 1000;
        vm.valueStart = "000001";

        vm.checkDigitLast = "-";
        vm.lastGeneratedNumber = 0;

        vm.currentWorthDigit = vm.worthDigit;
        vm.updateValues = updateValues;

        function initLastGenerated() {
            Vouchers.lastGenerated({worth: vm.worth.worth}, function (result) {
                vm.lastGeneratedNumber = result.number;
                updateLastGenerated();
            }, function (error) {
                vm.lastGeneratedNumber = 0;
                updateLastGenerated();
            });
        };

        function updateLastGenerated() {
            vm.lastGenerated = VoucherUtils.numberToString(vm.lastGeneratedNumber);
            Vouchers.checkDigit({number: vm.lastGeneratedNumber}, function (result) {
                vm.checkDigitLast = result.data;
            });
            vm.valueStart = VoucherUtils.numberToString(vm.lastGeneratedNumber + 1);
            updateValues();
        }

        function updateValues() {
            if (vm.lastGeneratedNumber >= 0) {
                vm.valueEnd = VoucherUtils.numberToString(parseInt(vm.valueStart) + vm.valueCount - 1);
                Vouchers.checkDigit({number: vm.valueStart}, function (result) {
                    vm.checkDigitStart = result.data;
                });
                Vouchers.checkDigit({number: vm.valueEnd}, function (result) {
                    vm.checkDigitEnd = result.data;
                });
            }
        }

        vm.invalid = function () {
            if (_.isNil(vm.valueStart)) {
                return true;
            }
            if (_.isNil(vm.valueEnd)) {
                return true;
            }
            if (parseInt(vm.valueStart) <= vm.lastGeneratedNumber) {
                return true;
            }
            if (vm.valueCount <= 0) {
                return true;
            }

            return false;
        }

        vm.validateStart = function (startValue, endValue) {
            if (startValue >= endValue) {
                return false;
            } else {
                return true;
            }
        }

        vm.generate = function () {

            Dialogs.ask("Jetzt " + vm.valueCount + " " + systemInfoLoader.plural + " von " + vm.worth.label + " erstellen?").then(function () {
                Loading.open(systemInfoLoader.plural + " werden erstellt...");
                Vouchers.generate({
                    worth: vm.worth.worth,
                    from: vm.valueStart,
                    to: vm.valueEnd
                }, function () {
                    initLastGenerated();
                    toastr.success(systemInfoLoader.plural + " wurden erstellt");
                    $state.go("stock-vouchers");
                    Loading.close();
                }, function (result) {
                    toastr.error(systemInfoLoader.plural + " konnten nicht erstellt werden!", result.data.message);
                    Loading.close();
                })
            })
        }

        // vm.updateValues();


    }
})();
