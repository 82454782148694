'use strict';

angular.module('voucherApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('login', {
                parent: 'blank',
                url: '/login',
                data: {
                    authorities: []
                },
                views: {
                    'content@blank': {
                        templateUrl: 'app/account/login/login.html',
                        controller: 'LoginController'
                    }
                }
            });
    });
