(function () {
    'use strict';

    angular
        .module('voucherApp')
        .controller('StatisticsController', StatisticsController);


    StatisticsController.$inject = ['$scope', 'Dialogs', 'toastr', 'VoucherUtils', 'systemInfoLoader', 'AuthServerProvider', 'GraphQL', 'TabUtil'];

    function StatisticsController($scope, Dialogs, toastr, VoucherUtils, systemInfoLoader, AuthServerProvider, GraphQL, TabUtil) {
        var vm = this;
        vm.accessToken = AuthServerProvider.getToken();
        vm.worths = systemInfoLoader.worths;

        vm.tabs = TabUtil.watch($scope, "tab");
        vm.loading = true;
        vm.statistics = [];
        vm.export = {
            stockInDepot: {from: '2016-01-01', to: '2050-12-31', type: 'InDepot'},
            stockInIssue: {from: '2016-01-01', to: '2050-12-31', type: 'InIssue'},
            stockOutside: {from: '2016-01-01', to: '2050-12-31', type: 'Outside'},
            stockInSubmission: {from: '2016-01-01', to: '2050-12-31', type: 'InSubmission'},
            stockInAudit: {from: '2016-01-01', to: '2050-12-31', type: 'InAudit'},
            stockDestroyed: {from: '2016-01-01', to: '2050-12-31', type: 'Destroyed'},
            stockCleared: {from: '2016-01-01', to: '2050-12-31', type: 'Cleared'}
        };

        function load() {
            vm.loading = true;
            var request = {
                name: "statistics",
                values: [
                    {name: "stockInDepot", values: ["...stats"]},
                    {name: "stockInIssue", values: ["...stats"]},
                    {name: "stockOutside", values: ["...stats"]},
                    {name: "stockInSubmission", values: ["...stats"]},
                    {name: "stockInAudit", values: ["...stats"]},
                    {name: "stockDestroyed", values: ["...stats"]},
                    {name: "stockCleared", values: ["...stats"]},
                    {name: "stockOutdated", values: ["...stats"]},
                    {name: "totalSales", values: ["...stats"]},
                    {name: "totalIssuerSales", values: [{name: "issuer", values: ["id", "name"]}, {name: "stats", values: ["...stats"]}]}
                ],
                fragments: [
                    {name: "stats", on: "VoucherStat", values: ["worth", "value", "count", "minSerial", "maxSerial"]}
                ]
            }
            GraphQL.then(request, function (result) {
                vm.statistics = result.statistics;
                vm.loading = false;
            })
        }


        load();




        vm.datePickerOptions = {maxDate: new Date()};
        vm.historyDate = new Date();
        vm.historyLoaded = false;
        vm.historyLoading = false;
        vm.historyStatistics = [];
        vm.loadHistory = loadHistory;

        function loadHistory() {
            var date = this.historyDate.toISOString().split('T')[0];
            vm.historyLoading = true;
            // var date = "2021-01-01";
            var request = {
                name: "statisticsHistory",
                variables: {"at": date},
                values: [
                    {name: "stockInDepot", values: ["...stats"]},
                    {name: "stockInIssue", values: ["...stats"]},
                    {name: "stockOutside", values: ["...stats"]},
                    {name: "stockInSubmission", values: ["...stats"]},
                    {name: "stockInAudit", values: ["...stats"]},
                    {name: "stockDestroyed", values: ["...stats"]},
                    {name: "stockCleared", values: ["...stats"]},
                    {name: "stockOutdated", values: ["...stats"]},
                    {name: "totalSales", values: ["...stats"]},
                    {name: "totalIssuerSales", values: [{name: "issuer", values: ["id", "name"]}, {name: "stats", values: ["...stats"]}]}
                ],
                fragments: [
                    {name: "stats", on: "VoucherStat", values: ["worth", "value", "count", "minSerial", "maxSerial"]}
                ]
            }
            GraphQL.then(request, function (result) {
                vm.historyStatistics = result.statisticsHistory;
                vm.historyLoading = false;
                vm.historyLoaded = true;
            })
        }



    }
})();
