'use strict';

angular.module('voucherApp')
    .factory('Dockets', function ($resource) {
        return $resource('api/dockets/:id', {}, {

            'getDockets': {
                url: 'api/dockets',
                method: 'GET',
                isArray: true
            },
            'createList': {
                method: 'POST',
                isArray: true,
                url: 'api/dockets/list'
            },
            'createRange': {
                method: 'POST',
                isArray: false,
                url: 'api/dockets/range'
            },
            'deleteDocket': {
                method: 'DELETE',
                isArray: false,
                url: 'api/dockets/:id'
            },
            'removeVoucher': {
                method: 'DELETE',
                isArray: false,
                url: 'api/dockets/:docketId/voucher/:voucherId'
            },
            'createDocket': {
                method: 'GET',
                isArray: false,
                url: 'api/dockets/create-member/:memberId'
            },
            'createDockets': {
                method: 'GET',
                isArray: false,
                url: 'api/dockets/create'
            },
            'resendDockets': {
                method: 'GET',
                isArray: false,
                url: 'api/dockets/resend'
            }

        });
    });
