(function () {
    'use strict';

    angular
        .module('voucherApp')
        .controller('ClearingVouchersDialogController', ClearingVouchersDialogController);


    ClearingVouchersDialogController.$inject = ['$scope', 'Dialogs', 'Dockets', 'toastr', 'VoucherUtils', 'Vouchers', 'systemInfoLoader', 'member', 'Loading', '$state', 'GraphQL', '$q', 'Submissions', 'Principal', 'TabUtil', 'Issuers'];

    function ClearingVouchersDialogController($scope, Dialogs, Dockets, toastr, VoucherUtils, Vouchers, systemInfoLoader, member, Loading, $state, GraphQL, $q, Submissions, Principal, TabUtil, Issuers) {
        var vm = this;
        vm.worths = systemInfoLoader.worths;
        vm.member = member;
        vm.submissions = [];

        vm.tabs = TabUtil.watch($scope, "dialog-tab");

        $scope.$watch("vm.tabs.active", function (newVal) {
            $scope.$emit('bb.tab-changed', {});
        });
        updateSummery();

        vm.datePickerOptions = {maxDate: new Date()};


        Principal.identity().then(function (identity) {
            if (!identity.roleAdmin && !identity.roleAgent) {
                if (identity.memberId !== vm.member.id) {
                    $state.go('accessdenied');
                }
            }
        });



        function updateSummery() {
            vm.summary = [];
            vm.worths.forEach(function (worth) {
                vm.summary.push({worth: worth.worth, min: null, max: null, minSerial: null, maxSerial: null, count: 0, value: 0, worthValue: worth.value});
            });
            vm.submissions.forEach(function (sale) {
                var v = sale.voucher;
                vm.summary.forEach(function (sum) {
                    if (sum.worth === v.worth) {
                        sum.count++;
                        sum.value = sum.count * sum.worthValue;
                        if (sum.min === null || sum.min > v.number) {
                            sum.min = v.number;
                            sum.minSerial = v.serialString;
                        }
                        if (sum.max === null || sum.max < v.number) {
                            sum.max = v.number;
                            sum.maxSerial = v.serialString;
                        }
                    }
                })
            })
        }


        vm.dockets = {
            page: 1,
            size: 20,
            total: 0,
            predicate: 'date',
            reverse: false,
            load: loadDockets,
            content: []
        };



        function loadDockets() {

            var request = {
                name: "member",
                variables: {id: vm.member.id},
                values: ["id",
                    {
                        name: "dockets",
                        variables: {
                            page: vm.dockets.page - 1,
                            size: vm.dockets.size,
                            sort: sort()
                        },
                        values: ["id", "date", "state",
                            {
                                name: "audit", values: ["id", "approved", "date"]
                            },
                            {
                                name: "statsTotal", values: ["count", "total"]
                            }
                        ]
                    }]
            };

            GraphQL.then(request, function (result, ctx, headers) {
                vm.dockets.total = headers('X-Total-Count');
                vm.dockets.content = result.member.dockets;
            })
        }


        function sort() {
            var result = [vm.dockets.predicate + ',' + (vm.dockets.reverse ? 'asc' : 'desc')];
            if (vm.dockets.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }


        vm.openSubmissions = {
            page: 1,
            size: 20,
            total: 0,
            predicate: 'submissiondate',
            reverse: false,
            load: loadSubmissions,
            content: []
        };

        function loadSubmissions() {

            var request = {
                name: "member",
                variables: {id: vm.member.id},
                values: ["id",
                    {
                        name: "submissionsOpen",
                        variables: {
                            page: vm.openSubmissions.page - 1,
                            size: vm.openSubmissions.size,
                            sort: sortSubmissions()
                        },
                        values: ["id", "submissiondate", "redemptiondate", "state",
                            {
                                name: "voucher", values: ["id", "state", "serialString", "worth", "worthLabel", "worthValue"]
                            }
                        ]
                    }]
            };

            GraphQL.then(request, function (result, ctx, headers) {
                vm.openSubmissions.total = headers('X-Total-Count');
                vm.openSubmissions.content = result.member.submissionsOpen;
            })
        }


        function sortSubmissions() {
            var result = [vm.openSubmissions.predicate + ',' + (vm.openSubmissions.reverse ? 'asc' : 'desc')];
            if (vm.openSubmissions.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }


        vm.removeSubmittedSubmission = function (submission, index) {
            Dialogs.ask("Wirklich diese Einreichung löschen?").then(function () {
                Submissions.deleteSubmission({id: submission.id}, function () {
                    toastr.success("Einreichung gelöscht");
                    vm.loadDockets();
                }, function () {
                    toastr.error("Diese Einreichung kann nicht mehr gelöscht werden!");
                })
            })
        };


        vm.submissionStats = {
            range: {
                startDate: moment(),
                endDate: moment()
            }
        };

        $scope.$watch('vm.submissionStats.range', loadSubmissionStats, true);

        function loadSubmissionStats(range) {
            if (!_.isNil(range)) {

                var from = range.startDate.format('YYYY-MM-DD');
                var to = range.endDate.format('YYYY-MM-DD');
                if (from === "Invalid date" || to === "Invalid date") {
                    toastr.error("Datum ist nicht valide!");
                    return;
                }

                var request = {
                    name: "member",
                    variables: {
                        id: vm.member.id
                    },
                    values: [{
                        name: "submissionStats",
                        variables: {
                            from: from,
                            to: to
                        },
                        values: ["worth", "count", "value", "minSerial", "maxSerial"]
                    }]
                }

                GraphQL.then(request, function (result) {
                    vm.submissionStats.stats = result.member.submissionStats;
                });

            }
        }

        loadDockets();
        loadSubmissions();
        vm.loadDockets = function () {
            loadDockets();
            loadSubmissions();
            loadSubmissionStats(vm.submissionStats.range);
        };


        vm.createDockets = function(){
            Dialogs.ask("Sollen nun Abrechnungsbögen für alle offenen Einreichungen erstellten werden und an das Mitglied geschickt werden?", "Dies kann etwas Zeit in Anspruch nehmen!").then(function(){
                Loading.open("Erstelle Abrechnungsbogen...");
                Dockets.createDocket({memberId: vm.member.id}, function(){
                    toastr.success("Abrechnungsbogen wurde erstellt!");
                    Loading.close();
                }, function () {
                    toastr.error("Abrechnungsbogen konnte nicht erstellt werden!");
                    Loading.close();
                })
            })
        }

    }
})();
