(function () {
    'use strict';

    angular
        .module('voucherApp')
        .factory('menuHandler', menuHandler);

    menuHandler.$inject = ['$rootScope', '$state', '$window', '$timeout'];

    function menuHandler($rootScope, $state, $window, $timeout) {

        var initialWidth;

        return {
            initialize: initialize,
            toggle: toggle,
            hide: hide,
            show: show
        };


        function getWindowSize() {
            var sizes = {
                width: $(window).width(),
                height: $(window).height()
            };
            if (window.giniDevice && window.giniDevice.width) {
                sizes.width = window.giniDevice.width;
                sizes.height = window.giniDevice.height;
            }
            return sizes;
        }

        function setSizeToWindow() {
            var size = getWindowSize();
            $('#wrapper').css('max-width', size.width + "px");
        }

        function initialize() {


            var width = getWindowSize().width;
            // set to expanded
            if (width <= 1024) {
                $('div.app-container').removeClass('expanded');
                $('button.navbar-expand-toggle').removeClass('fa-rotate-90');
            } else {
                $(".app-container").addClass("expanded");
            }
            setSizeToWindow();

            window.addEventListener("resize", function(){
                setSizeToWindow();
            });

            $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
                var width = getWindowSize().width;
                if (width <= 768) {
                    if ($(".app-container").hasClass("expanded")) {
                        $('.app-container').removeClass('expanded');
                        $('button.navbar-expand-toggle').removeClass('fa-rotate-90');
                    }
                }
            });

        }


        function hide() {
            $(".app-container").addClass("hidemenu");
        }

        function show(time){
            if ($(".app-container").hasClass("hidemenu")) {
                $('.app-container').removeClass('hidemenu');
            }
            if(time){
                $timeout(hide, time);
            }
        }


        function toggle() {
            // is opened?
            if ($(".app-container").hasClass("expanded")) {
                $('.app-container').removeClass('expanded');
                $('button.navbar-expand-toggle').removeClass('fa-rotate-90');
            } else {
                $(".app-container").addClass("expanded");
                $('button.navbar-expand-toggle').addClass('fa-rotate-90');
            }
        }

    }
})();
