'use strict';

angular.module('voucherApp')
    .controller('NavbarController', function ($scope, $rootScope, $location, $state, Auth, Principal, menuHandler, $stateParams, systemInfo) {
        $scope.isAuthenticated = Principal.isAuthenticated;
        $scope.$state = $state;
        $scope.$stateParams = $stateParams;
        $scope.user = {};
        $scope.openedSubmenu = null;
        $scope.activeSubmenu = null;
        $scope.identity = null;

        $scope.label = {
            singular: systemInfo.singular(),
            tenant: systemInfo.tenant()
        };

        Principal.identity().then(function(identity){
           $scope.user = identity.user;
           $scope.identity = identity;
        });



        $scope.navbarExpandToggle = function(){
            menuHandler.toggle();
        };

        $scope.resolveActiveSubmenu = function (key) {
            if ($state.includes(key)) {
                $scope.activeSubmenu = key;
                $scope.openedSubmenu = key;
            }
        };

        $scope.resolveActive = function () {
            $scope.resolveActiveSubmenu("home");
            $scope.resolveActiveSubmenu("sale-vouchers");
            $scope.resolveActiveSubmenu("submit-vouchers");
            $scope.resolveActiveSubmenu("clearing-vouchers");
            $scope.resolveActiveSubmenu("audit");
            $scope.resolveActiveSubmenu("statistics");
            $scope.resolveActiveSubmenu("book-out");

            if ($state.includes('stock-generate') || $state.includes('stock-vouchers')|| $state.includes('stock-dealout')){
                $scope.activeSubmenu = 'stock';
                $scope.openedSubmenu = 'stock';
            }


            if ($state.includes('licence')){
                $scope.activeSubmenu = 'settings';
                $scope.openedSubmenu = 'settings';
            }

            if ($state.includes('update')){
                $scope.activeSubmenu = 'settings';
                $scope.openedSubmenu = 'settings';
            }


            if ($state.includes('settings') || ($state.includes('plugin.view') && $scope.isSubView('settings'))) {
                $scope.activeSubmenu = 'settings';
                $scope.openedSubmenu = 'settings';
            }
            if ($state.includes('system') || $state.includes('admin') || ($state.includes('plugin.view') && $scope.isSubView('system'))) {
                $scope.activeSubmenu = 'system';
                $scope.openedSubmenu = 'system';
            }

        };



        $scope.openSubmenu = function (key) {
            if ($scope.openedSubmenu === key) {
                $scope.openedSubmenu = null;
            } else {
                $scope.openedSubmenu = key;
            }
        };



        $scope.logout = function () {
            Auth.logout();
            $state.go('login');
        };

        $rootScope.$on('$stateChangeSuccess', function () {
            $scope.resolveActive();
        });


        $scope.resolveActive();
    });
