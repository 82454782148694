'use strict';

angular.module('voucherApp')
    .directive('exportStatsButtons', function (AuthServerProvider) {
        return {
            scope: {
                settings: '=exportSettings'
            },
            templateUrl: 'app/directives/exportStatsButtons/exportStatsButtons.html',
            restrict: 'EA',
            transclude: true,
            replace: true,
            link: function ($scope, element, $attrs, ngModel) {

                $scope.accessToken = AuthServerProvider.getToken();

                $scope.$watch("settings", function (newVal) {
                    if (!_.isNil(newVal)) {


                        var url = "type=" + $scope.settings.type + "&from=" + $scope.settings.from + "&to=" + $scope.settings.to;
                        if (!_.isNil($scope.settings.issuer)) {
                            url = url + "&issuerId=" + $scope.settings.issuer;
                        }
                        if (!_.isNil($scope.settings.member)) {
                            url = url + "&memberId=" + $scope.settings.member;
                        }
                        $scope.pdfWith = "api/vouchers/statistics/pdf/true?" + url + "&access_token=" + $scope.accessToken;
                        $scope.pdfWithout = "api/vouchers/statistics/pdf/false?" + url + "&access_token=" + $scope.accessToken;

                        $scope.xlsWith = "api/vouchers/statistics/xls/true?" + url + "&access_token=" + $scope.accessToken;
                        $scope.xlsWithout = "api/vouchers/statistics/xls/false?" + url + "&access_token=" + $scope.accessToken;
                    }
                });

            }
        }
    });
