(function () {
    'use strict';

    angular
        .module('voucherApp')
        .filter('voucherState', voucherState);

    voucherState.$inject = [];

    function voucherState() {
        return voucherState;

        function voucherState(state) {
            if (_.isNil(state)) {
                return state;
            }
            if (!_.isString(state)) {
                return state;
            }
            if (state === 'InDepot') {
                return "Im Lager";
            }
            if (state === 'InIssue') {
                return "In Ausgabestelle";
            }
            if (state === 'Outside') {
                return "Im Umlauf";
            }
            if (state === 'InSubmission') {
                return "Eingereicht";
            }
            if (state === 'InAudit') {
                return "In Prüfung";
            }
            if (state === 'Cleared') {
                return "Abgerechnet";
            }
            if (state === 'Destroyed') {
                return "Zerstört";
            }
            if (state === 'BookedOut') {
                return "Ausgebucht";
            }
            return state;

        }
    }
})();
