'use strict';

angular.module('voucherApp')
    .controller('LoginController', function ($rootScope, $scope, $state, $timeout, Auth, systemInfo) {
        $scope.user = {};
        $scope.errors = {};
        $scope.logindenied = false;

        $scope.rememberMe = true;
        $timeout(function () {
           $('#username').focus();
        });

        $scope.label = systemInfo.type();

        $scope.login = function (event) {
            event.preventDefault();
            Auth.login({
                username: $scope.username,
                password: $scope.password,
                rememberMe: $scope.rememberMe
            }).then(function () {
                $scope.authenticationError = false;
                $state.go('home');
            }).catch(function () {
                $scope.authenticationError = true;
            });
        };
    });
