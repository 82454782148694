'use strict';

angular.module('voucherApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('stock-generate', {
                parent: 'app',
                url: '/stock-generate',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SYSTEM', 'ROLE_AGENT']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/views/stock-generate/stock-generate.html',
                        controller: 'StockGenerateController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: 'Seriennummern generieren'
                }
            })
    });
