(function () {
    'use strict';

    angular
        .module('voucherApp')
        .controller('HomeController', HomeController);

    HomeController.$inject = ['$scope', 'Principal', '$state', 'toastr', 'systemInfoLoader', 'GraphQL', '$q', 'Dialogs', 'Sales', '$timeout'];

    function HomeController($scope, Principal, $state, toastr, systemInfoLoader, GraphQL, $q, Dialogs, Sales, $timeout) {
        var vm = this;

        vm.account = null;
        vm.messages = [];
        vm.validate = {valid: false, success: false, run: validateVoucher};

        vm.issuerId = null;
        vm.memberId = null;

        $scope.$watch("vm.validate.serial", function (newVal) {
            vm.validate.valid = false;
            vm.validate.success = false;
            if (!_.isNil(vm.validate.serial)) {
                if (vm.validate.serial.length === 8) {
                    vm.validate.valid = true;
                }
            }
        });

        function loadForMember() {

            if (_.isNil(vm.memberId)) {
                return;
            }
            var req = {
                name: "member",
                variables: {id: vm.memberId},
                values: [{
                    name: "dockets",
                    variables: {page: 0, size: 2, sort: "id,desc"},
                    values: ["id", "date", "state"]

                }]
            };
            GraphQL.then(req, function (result) {
                vm.member = result.member;
            });
        }

        function validateVoucher() {
            vm.validate.result = null;
            var request = {
                name: "voucherValidate",
                variables: {
                    serial: vm.validate.serial
                },
                values: ["id", "serialString", "state"]
            };
            GraphQL.then(request, function (result) {
                if (!_.isNil(result.voucherValidate)) {
                    toastr.success("Dieser " + systemInfoLoader.singular + " ist valide!");
                    vm.validate.result = result.voucherValidate;
                    vm.validate.success = true;
                } else {
                    vm.validate.success = false;
                    toastr.error("Dieser " + systemInfoLoader.singular + " existiert nicht!");
                }

            });

        }


        vm.activate = {
            valid: false,
            searchVouchers: searchVouchers,
            validate: activateValidate,
            onEnter: activateOnEnter,
            run: activateVoucher
        };

        function activateOnEnter($item, $model, $label, $event) {
            activateValidate();
            if ($event) {
                $event.preventDefault();
            }
            $timeout(function () {
                activateVoucher();
            }, 200);

        }

        function activateVoucher() {
            if (!vm.activate.valid) {
                return;
            }
            Dialogs.ask("Den " + systemInfoLoader.singular + " " + vm.activate.voucher.serialString + " nun mit heutigem Datum aktivieren?").then(function () {
                var request = {
                    issuer: {id: vm.issuerId},
                    vouchers: [{
                        voucher: vm.activate.voucher,
                        date: moment().format("YYYY-MM-DD")
                    }]
                };

                Sales.createList(request, function (result) {
                    toastr.success(systemInfoLoader.singular + " " + vm.activate.voucher.serialString + " wurde aktiviert");
                    vm.activate.valid = false;
                    vm.activate.voucher = null;
                }, function (error) {
                    toastr.error("Aktivierung war nicht möglich!");
                });


            });

        }

        function activateValidate() {
            vm.activate.valid = false;
            if (!_.isNil(vm.activate.voucher) && !_.isNil(vm.activate.voucher.serialString)) {
                vm.activate.valid = true;
            }
        }


        function searchVouchers(filter) {
            var request = {
                name: "issuer",
                variables: {
                    id: vm.issuerId
                },
                values: [{
                    name: "vouchersSearch",
                    variables: {states: ["InIssue", "InSubmission", "InAudit"], filter: filter, limit: 10},
                    values: ["id", "number", "worth", "serialString", "worthValue", "worthLabel"]
                }]
            };
            var deferred = $q.defer();
            GraphQL.then(request, function (data) {
                deferred.resolve(data.issuer.vouchersSearch);
            });
            return deferred.promise;
        }


        vm.salesStats = {
            range: {
                startDate: moment(),
                endDate: moment()
            }
        };

        vm.submissionStats = {
            range: {
                startDate: moment(),
                endDate: moment()
            }
        };

        $scope.$watch('vm.salesStats.range', function (range) {
            loadSaleStats();
        }, true);

        $scope.$watch('vm.salesStats.issuerId', function () {
            loadSaleStats();
        })

        $scope.$watch('vm.submissionStats.range', function (range) {
            loadSubmissionStats();
        }, true);

        $scope.$watch('vm.submissionStats.memberId', function (range) {
            loadSubmissionStats();
        }, true);


        function loadStats() {
            loadSaleStats();
            loadSubmissionStats();
        }

        function loadSaleStats() {
            var range = vm.salesStats.range;
            if (!_.isNil(range)) {

                var from = range.startDate.format('YYYY-MM-DD');
                var to = range.endDate.format('YYYY-MM-DD');
                if (from === "Invalid date" || to === "Invalid date") {
                    toastr.error("Datum ist nicht valide!");
                    return;
                }
                vm.salesStats.export = {
                    from: from,
                    to: to,
                    type: "sales"
                };
                var issuerId = vm.salesStats.issuerId;
                if (!_.isNil(issuerId)) {
                    var request = {
                        name: "issuer",
                        variables: {
                            id: issuerId
                        },
                        values: [{
                            name: "saleStats",
                            variables: {
                                from: from,
                                to: to
                            },
                            values: ["worth", "count", "value", "minSerial", "maxSerial"]
                        }]
                    };
                    vm.salesStats.export = {
                        issuer: issuerId,
                        from: from,
                        to: to,
                        type: "sales"
                    };
                    GraphQL.then(request, function (result) {
                        vm.salesStats.stats = result.issuer.saleStats;
                    });
                } else {
                    var request = {
                        name: "saleStats",
                        variables: {
                            from: from,
                            to: to
                        },
                        values: ["worth", "count", "value", "minSerial", "maxSerial"]
                    };
                    vm.salesStats.export = {
                        from: from,
                        to: to,
                        type: "sales"
                    };
                    GraphQL.then(request, function (result) {
                        vm.salesStats.stats = result.saleStats;
                    });

                }


            }
        }


        function loadSubmissionStats() {
            var range = vm.submissionStats.range;
            if (!_.isNil(range)) {

                var from = range.startDate.format('YYYY-MM-DD');
                var to = range.endDate.format('YYYY-MM-DD');
                if (from === "Invalid date" || to === "Invalid date") {
                    toastr.error("Datum ist nicht valide!");
                    return;
                }
                vm.submissionStats.export = {
                    from: from,
                    to: to,
                    type: "sales"
                };
                var memberId = vm.submissionStats.memberId;
                if (!_.isNil(memberId)) {
                    var request = {
                        name: "member",
                        variables: {
                            id: memberId
                        },
                        values: [{
                            name: "submissionStats",
                            variables: {
                                from: from,
                                to: to
                            },
                            values: ["worth", "count", "value", "minSerial", "maxSerial"]
                        }]
                    };
                    vm.submissionStats.export = {
                        member: memberId,
                        from: from,
                        to: to,
                        type: "submissions"
                    };
                    GraphQL.then(request, function (result) {
                        vm.submissionStats.stats = result.member.submissionStats;
                    });
                } else {
                    var request = {
                        name: "submissionStats",
                        variables: {
                            from: from,
                            to: to
                        },
                        values: ["worth", "count", "value", "minSerial", "maxSerial"]
                    };
                    vm.submissionStats.export = {
                        from: from,
                        to: to,
                        type: "submissions"
                    };
                    GraphQL.then(request, function (result) {
                        vm.submissionStats.stats = result.submissionStats;
                    });
                }


            }
        }



        function loadAccounts() {
            var request = [
                {name: "members", values: ["id", "name"]},
                {name: "revisors", values: ["id", "name"]},
                {name: "issuers", values: ["id", "name"]}
            ];
            GraphQL.query(request).then(function (result) {
                vm.members = result.data.members;
                vm.revisors = result.data.revisors;
                vm.issuers = result.data.issuers;
                getAccount();
            }, function () {
                getAccount();
            });
        }



        loadAccounts();






        function getAccount() {
            Principal.identity().then(function (account) {
                vm.account = account;
                vm.memberId = account.memberId;
                vm.issuerId = account.issuerId;
                vm.salesStats.issuerId = vm.issuerId;
                vm.submissionStats.memberId = vm.memberId;
                loadStats();
            }, function(){
                loadStats();
            });
        }

    }
})();
