'use strict';

angular.module('voucherApp')
    .directive('voucherTypeahead', function (GraphQL, $q, Vouchers) {
        return {
            scope: {
                model: '=voucherTypeahead',
                worth: '<',
                state: '@',
                valid: '='
            },
            templateUrl: 'app/directives/voucherTypeahead/voucherTypeahead.html',
            restrict: 'EA',
            transclude: true,
            replace: true,
            link: function ($scope, element, $attrs, ngModel) {


                $scope.valid = false;
                $scope.validate = function () {
                    $scope.valid = false;
                    var toTest = $scope.model;
                    if (_.isObject($scope.model)) {
                        toTest = $scope.model.serialString;
                    }
                    Vouchers.validate({number: toTest}, function (response) {
                        $scope.valid = response.result;
                    });

                    return true;
                };

                $scope.searchVouchers = function (filter) {
                    var worth = $scope.worth;
                    var state = $scope.state;
                    var request = {
                        name: "vouchersSearch",
                        variables: {worth: worth, state: state, filter: filter, limit: 20},
                        values: ["id", "number", "worth", "serialString", "worthValue", "worthLabel"]
                    };
                    var deferred = $q.defer();
                    GraphQL.then(request, function (data) {
                        deferred.resolve(data.vouchersSearch);
                    })
                    return deferred.promise;
                }


            }
        }
    });
