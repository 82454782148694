(function () {
    'use strict';

    angular
        .module('voucherApp')
        .controller('MembersController', MembersController);

    MembersController.$inject = ['Principal', 'Members', 'ParseLinks', '$state', 'pagingParams', 'paginationConstants', 'Dialogs', 'toastr'];

    function MembersController(Principal, Members, ParseLinks, $state, pagingParams, paginationConstants, Dialogs, toastr) {
        var vm = this;

        vm.loadAll = loadAll;
        vm.members = [];
        vm.page = 1;
        vm.totalItems = null;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.transition = transition;

        vm.loadAll();


        vm.removeMember = function (member) {
            Dialogs.ask("Ausgabestelle \"" + member.name + "\" wirklich löschen?").then(function () {
                Members.delete({id: member.id}, function () {
                    toastr.success("Ausgabestelle gelöscht!");
                    loadAll();
                }, function () {
                    toastr.error("Ausgabestelle konnte nicht gelöscht werden!");
                })
            })
        }

        function loadAll() {
            Members.query({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
        }

        function onSuccess(data, headers) {
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.page = pagingParams.page;
            vm.members = data;
        }

        function onError(error) {

        }

        function sort() {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }
    }
})();
